import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import styles from './index.module.scss'
import { Summary, Location, Priorities, RiskTolerance, KeyPriorities } from './Summary';

import { UserAPI } from '@/api/user';
import { Layout } from '@/components/Layout';
import { Survey } from '@/components/v2Survey';

export const QuizSummary = ({ inApp = true }) => {
    const [summary, setSummary] = useState(null);
    const [quizComplete, setQuizComplete] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetch = async () => {
            const base = await UserAPI.getBaseQuiz();
            const questions = await UserAPI.getAllQuestions();
            if (questions.length !== 27) {
                setLoading(false);
                setQuizComplete(false);
            } else {
                setQuizComplete(true);
                const sections = await UserAPI.getSectionResponse();
                const new_quiz = mergeResponses(base, questions, sections);
                const body = {
                    "sections": new_quiz
                }

                const response = await UserAPI.summary(body);
                setLoading(false);
                setSummary(response);
            }
        }
        fetch();
    }, [])

    const mergeResponses = (survey, questions, sections) => {
        const responseMap = questions.reduce((map, response) => {
            map[response.question] = response.response;
            return map;
        }, {});

        let index = 0;
        survey.forEach(section => {
            section.section_response = sections[index].response;
            section.questions.forEach(question => {
                if (responseMap[question.id] !== undefined) {
                    question.response = responseMap[question.id];
                    question.satisfied = true;
                    question.updated = true;
                }
            });
            index++;
        });

        return survey;
    }

    return (
        <>
            {
                inApp ?
                    (
                        <Layout className={styles.layout}>
                            <div className='container'>
                                {loading ? (
                                    <h1>
                                        Loading your summary...it may take a minute
                                    </h1>
                                ) : (
                                    <>
                                        {quizComplete ? (
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '50px', textAlign: 'left' }}>
                                                {summary && (<Summary data={summary?.SummaryofHouseholdComposition} />)}
                                                {summary && (<Location data={summary?.LocationAndConvenience} />)}
                                                {summary && (<Priorities data={summary?.SummarizePriorities} />)}
                                                {summary && (<RiskTolerance data={summary?.RiskTolerance} />)}
                                                {summary && (<KeyPriorities data={summary?.KeyPriorities} />)}
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '50px', textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                                                <h1>
                                                    Please complete the quiz first and then we can provide a summary
                                                </h1>
                                                <Survey title={"Take Intake Quiz"} />
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </Layout>
                    )
                    :
                    (
                        <div className='container text-center'>
                            {loading ? (
                                <h1>
                                    Loading your summary...it may take a minute
                                </h1>
                            ) : (
                                <>
                                    {quizComplete ? (
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '50px', textAlign: 'left' }}>
                                            {summary && (<Summary data={summary?.SummaryofHouseholdComposition} />)}
                                            {summary && (<Location data={summary?.LocationAndConvenience} />)}
                                            {summary && (<Priorities data={summary?.SummarizePriorities} />)}
                                            {summary && (<RiskTolerance data={summary?.RiskTolerance} />)}
                                            {summary && (<KeyPriorities data={summary?.KeyPriorities} />)}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '50px', textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                                            <h1>
                                                Please complete the quiz first and then we can provide a summary
                                            </h1>
                                            <Survey title={"Take Intake Quiz"} />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )
            }
        </>
    )
}

QuizSummary.propTypes = {
    inApp: PropTypes.bool,
}