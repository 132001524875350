import PropTypes from 'prop-types';
import React from 'react';

import { Additional, Details, KeyPoints, Table } from './SummaryLayout';

export const Summary = ({ data }) => {
    return (
        <div style={data ? {} : { display: 'none' }}>
            <h2 style={{ textAlign: 'center' }}>Summary of Household Composition</h2>
            <Details details={data.Details} />
            <Table table={data.Table} />
            <KeyPoints keyPoints={data.KeyPoints} />
            <Additional additional={data.Additional} />
        </div>
    );
};

Summary.propTypes = {
    data: PropTypes.any
}

export const Location = ({ data }) => {
    return (
        <div style={data ? {} : { display: 'none' }}>
            <h2 style={{ textAlign: 'center' }}>Location and Convenience</h2>
            <Details details={data?.Details} />
            <Table table={data?.Table} />
            <KeyPoints keyPoints={data?.KeyPoints} />
            <Additional additional={data?.Additional} />
        </div>
    );
};

Location.propTypes = {
    data: PropTypes.any
}

export const Priorities = ({ data }) => {
    return (
        <div style={data ? {} : { display: 'none' }}>
            <h2 style={{ textAlign: 'center' }}>Property Priorities</h2>
            <Details details={data?.Details} />
            <Table table={data?.Table} />
            <KeyPoints keyPoints={data?.KeyPoints} />
            <Additional additional={data?.Additional} />
        </div>
    );
};

Priorities.propTypes = {
    data: PropTypes.any
}

export const RiskTolerance = ({ data }) => {
    return (
        <div style={data ? {} : { display: 'none' }}>
            <h2 style={{ textAlign: 'center' }}>Risk Tolerance</h2>
            <Details details={data?.Details} />
            <Table table={data?.Table} />
            <KeyPoints keyPoints={data?.KeyPoints} />
            <Additional additional={data?.Additional} />
        </div>
    );
};

RiskTolerance.propTypes = {
    data: PropTypes.any
}

export const KeyPriorities = ({ data }) => {
    return (
        <div style={data ? {} : { display: 'none' }}>
            <h2 style={{ textAlign: 'center' }}>Key Priorities</h2>
            <Details details={data?.Details} />
            <Table table={data?.Table} />
            <KeyPoints keyPoints={data?.KeyPoints} />
            <Additional additional={data?.Additional} />
        </div>
    );
};

KeyPriorities.propTypes = {
    data: PropTypes.any
}