import { CheckFat } from '@phosphor-icons/react';
import { track } from '@vercel/analytics/react';
import { useState, useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { toast } from "react-toastify";

import styles from './index.module.scss';

import { ReportAPI } from '@/api/report';
import { UserAPI } from '@/api/user';
import addWhiteIcon from '@/assets/icons/addWhite.svg';
import { Demo } from '@/components/Demo';
import { HousesView } from '@/components/HousesView';
import { Button, Contents, Layout, Row, Icon, Input, Col } from '@/components/Layout';
import { Survey } from '@/components/v1Survey';
import { WarnUpload } from '@/components/WarnUpload';
import { ColorAccent } from "@/constants";

const MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const Home = () => {
    const [reports, setReports] = useState([]);
    const [userPreferences, setUserPreferences] = useState(0);
    const [warn, setWarn] = useState(false);
    const [demo, setDemo] = useState(false);
    const [chosenLocation, setChosenLocation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { ref, autocompleteRef } = usePlacesWidget({
        apiKey: MAPS_API_KEY,
        onPlaceSelected: (place) => {
            place?.formatted_address && setChosenLocation(place.formatted_address)
        },
        options: {
            types: ['address'],
            componentRestrictions: { country: 'us' },
        },
    });

    useEffect(() => {
        ReportAPI.getReports().then(data => {
            setReports(data);
            if (data.length <= 1) {
                setDemo(true)
            }
        });
    }, []);

    useEffect(() => {
        UserAPI.getUserPreferences().then(data => {
            setUserPreferences(data.length);
        })
    }, [])

    const createNewReport = e => {
        e.preventDefault();
        if (chosenLocation && !isLoading) {
            setIsLoading(true)
            ReportAPI.uploadNew(chosenLocation).then(data => {
                ref.current.value = '';
                setChosenLocation(null);
                toast.success("Added a new home!");
                setIsLoading(false)
                ReportAPI.getReports().then(data => {
                    setReports(data);
                    const has_reports = data.filter(report => report.has_report);
                    has_reports.length < 2 && setWarn(true)
                });
            }).catch(err => setIsLoading(false));
        }
    };

    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            createNewReport(event);
        }
    }

    return (
        <Layout className={styles.layout}>
            <Col className={styles.allContents}>
                <Contents>
                    <Row style={{ justifyContent: 'flex-end' }}>
                        <Demo isOpen={demo} onClose={() => { setDemo(false) }} onNext={() => { setDemo(false) }} />
                        <Survey title={userPreferences < 8 ? <>Take our Buyer Preference Quiz</> : <>Quiz completed <CheckFat color={'green'} weight={'fill'} size={24} /></>} />
                    </Row>
                    <Row style={{ justifyContent: 'center' }} className={styles.mainTitle}>
                        Explore Beyond the Front Door
                    </Row>
                    <Row style={{ justifyContent: 'center', marginTop: '30px' }}>
                        <div className={styles.addressInputBar}>
                            <input
                                onKeyDown={handleKeyDown}
                                ref={ref}
                                placeholder={'Add addresses of homes you\'re seriously considering'}
                                className={styles.input}
                                style={{ opacity: isLoading ? '.5' : '1' }}
                                disabled={isLoading}
                            />
                            <Button className={styles.add_btn} colorAccent={ColorAccent.white} onClick={createNewReport}>
                                <Icon src={addWhiteIcon} />
                            </Button>
                        </div>
                    </Row>
                    <WarnUpload isOpen={warn} onClose={() => (setWarn(false))} onNext={() => (setWarn(false))} />
                </Contents>
                <HousesView reports={reports} />
            </Col>
        </Layout>
    );
};
