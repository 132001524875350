import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './index.module.scss';

import { ReportAPI } from '@/api/report';
import { UserAPI } from '@/api/user';
import fillerHouse from '@/assets/fillerHouse.png';
import angleArrowDown from '@/assets/icons/angleArrowDown.svg';
import angleArrowUp from '@/assets/icons/angleArrowUp.svg';
import {Contents, Col, Layout, Row, Img, Input} from '@/components/Layout';
import { Survey } from '@/components/v1Survey';
import { getReportAddresses } from '@/utils';
import * as mainContents from '@/views/Comparison/main';

const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

const Tabs = {
    yourInsights: {
        key: 'your-insights',
        name: 'Your Insights',
    },
    problemAreas: {
        key: 'problem-areas',
        name: 'Problem Areas',
    },
    // byRoom: {
    //     key: 'by-room',
    //     name: 'By Room',
    // },
};

const tabsComponents = {
    [Tabs.yourInsights.key]: mainContents,
    [Tabs.problemAreas.key]: {
        Body: props => <div>NOT IMPLEMENTED</div>,
        Controls: props => <></>,
    },
    // [Tabs.byRoom.key]: {
    //     Body: props => <div>NOT IMPLEMENTED</div>,
    //     Controls: props => <></>,
    // },
};

const defaultTab = Tabs.yourInsights;

export const Comparison = () => {
    const housesRef = useRef(null);
    const { hash } = useLocation();
    const [reports, setReports] = useState([]);
    const [data, setData] = useState({});
    const [allowComp, setAllowComp] = useState(false);


    useEffect(() => {
        ReportAPI.getReports().then(resp => {
            let temp = resp.filter(datum => datum.details.mlsid)
            setReports(temp);

            const copy = temp.reduce((acc, item) => {
                acc[item.id] = item;
                return acc;
            }, {});
            setData(copy);
        })
    }, []);

    useEffect(() => {
        UserAPI.getUserPreferences()
            .then((data) => {
                if (data.length === 8) {
                    setAllowComp(true);
                }
            })
    }, [])

    const [comparisons, setComparisons] = useState([]);
    const [main, setMain] = useState(false)
    const [observed, setObserved] = useState([]);

    useEffect(() => {
        if (comparisons.length === 2) {
            if (!main) setMain(true);
            setDropdown(false);
            setObserved(comparisons);
        }
    }, [comparisons.length, main]);

    const [currentTab, setCurrentTab] = useState(defaultTab.key);
    const [tabState, setTabState] = useState({});

    const tabComponents = tabsComponents[currentTab] || tabsComponents[defaultTab.key];
    const TabContents = tabComponents.Body;
    const TabControls = tabComponents.Controls;

    useEffect(() => {
        setCurrentTab(hash.replace('#', '') || defaultTab.key);
        setTabState({});
    }, [hash]);

    const [dropdown, setDropdown] = useState(false);

    const isChecked = (value) => {
        return comparisons.includes(value);
    }

    const handleChange = (reportId) => {
        const value = parseInt(reportId)
        if (isChecked(value)) {
            const updatedChoices = comparisons.filter(comparison => comparison !== value)
            setComparisons(updatedChoices);
        } else if (comparisons.length !== 2) {
            setComparisons([...comparisons, value]);
        }
    }

    return (
        <Layout className={styles.layout}>
            <Contents style={{ display: 'flex', flexDirection: 'column' }}>
                {allowComp ?
                    <>
                        <Row style={{ marginTop: 40 }}>
                            <Col>
                                <h1 className={styles.title}>Compare Properties</h1>
                                {comparisons.length === 2 ? <h2 className={styles.subtitle}>
                                    <b>{data[observed[0]]?.name === "Sample Report" ? "Sample Report" : getReportAddresses(data[observed[0]]).address2} </b>
                                    and
                                    <b> {data[observed[1]]?.name === "Sample Report" ? "Sample Report" : getReportAddresses(data[observed[1]]).address2}</b>
                                </h2> : ""}
                            </Col>
                        </Row>
                        {comparisons.length === 0 ? <h3>No Homes Selected.</h3> : comparisons.length === 1 ? <h3>Select two homes to compare.</h3> : ""}
                        <Row className={styles.dropdown_wrapper}>
                            <Col>
                                <div className={styles.dropdownHeaderAlign + (comparisons.length < 2 ? " " + styles.dropdownspace : "")}>
                                    <div className={styles.dropdownHeader} onClick={e => (e.preventDefault(), setDropdown(!dropdown))}>
                                        <Row style={{ justifyContent: 'space-between', alignItems: 'center', flexWrap: "nowrap" }}>
                                            <p style={{ fontSize: "19px", lineHeight: "0.7" }}>{`Compare between (${comparisons.length}/2)`}</p>
                                            <Img src={!dropdown ? angleArrowDown : angleArrowUp} />
                                        </Row>
                                    </div>
                                    {dropdown && (
                                        <div className={styles.dropdown}>
                                            <p style={{ marginTop: '5px' }}>Please, choose up to 2 options*</p>
                                            <div>
                                                {reports.map((report, index) => (
                                                    <Row key={index} className={styles.dropdownRow} onClick={() => handleChange(report.id)}>
                                                        <Input style={{ marginRight: '10px', width: '18px', height: '18px' }} type="checkbox" value={report.id} checked={isChecked(report.id)} onChange={() => handleChange(report.id)} />
                                                        <Img style={{ width: '100px', borderRadius: '10px', marginRight: '5px', height: "70px" }} src={report.details.imgSrc || fillerHouse} preloadSrc={fillerHouse} />
                                                        <Col className={styles.dropdownContent}>
                                                            <p>{report?.name === "Sample Report" ? "Sample Report" : getReportAddresses(report).address2}</p>
                                                            <p>{report?.name === "Sample Report" ? "" : getReportAddresses(report).address1}</p>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            <TabControls propertyId1={observed[0]} propertyId2={observed[1]} report1={data[observed[0]]} report2={data[observed[1]]} setState={setTabState} state={tabState} comparison={comparisons} />
                        </Row>

                        {comparisons.length === 2 && <TabContents propertyId1={observed[0]} propertyId2={observed[1]} report1={data[observed[0]]} report2={data[observed[1]]} state={tabState} comparison={comparisons} />}
                    </>
                    :
                    <Col className={`${styles.no_quiz} mt-4`}>
                        <h1 className={styles.title}>Please take our Buyer Preference Quiz to get comparisons based on your preferences.</h1>
                        <Survey title={"Take our Buyer Preference Quiz"} className='mt-2' />
                    </Col>
                }
                <Row className={styles.footer}>
                        <span>©2024 Homescore&nbsp; </span> | <Link to={DOCS_TERMS_URL}>&nbsp; Terms of Service&nbsp; </Link> |
                        <Link to={DOCS_PRIVACY_URL}>&nbsp; Privacy Policy</Link>
                </Row>
            </Contents>
        </Layout>
    );
};