import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import styles from './index.module.scss';

import leftAngleBlackIcon from '@/assets/icons/leftAngleBlack.svg';
import rightAngleBlackIcon from '@/assets/icons/rightAngleBlack.svg';
import { HouseCard } from '@/components/HouseCard';
import { Col, Contents, Row, Icon } from '@/components/Layout';

const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

export const HousesView = ({ reports, sort_key = "id" }) => {

    const [currHouses, setCurrHouses] = useState(reports)

    useEffect(() => {
        if (reports.length > 0) {
            let copy = reports
            copy.sort((a, b) => {
                if (a[sort_key] > b[sort_key]) {
                    return -1;
                } else if (a[sort_key] < b[sort_key]) {
                    return 1;
                }

                return 0;
            })

            setCurrHouses(copy);
        }
    }, [reports])

    return (
        <Col className={styles.contents + ' container-fluid text-center'}>
            <Contents>
                <Row>
                    <Col>
                        <div className={styles.title}>{"Your Homes"}</div>
                        <div className={styles.subtitle}>Based on your view history</div>
                    </Col>
                    <Row className={styles.swiper_navigation_wrapper}>
                        <Icon src={leftAngleBlackIcon} size={36} className={'swiper-button-prev-unique'}/>
                        <Icon src={rightAngleBlackIcon} size={36} className={'swiper-button-next-unique'}/>
                    </Row>
                </Row>
            </Contents>
            <Swiper modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next-unique',
                    prevEl: '.swiper-button-prev-unique'
                }}
                autoHeight={true}
                speed={600}
                className={styles.houses}
                breakpoints={{
                    [1200]: {
                        spaceBetween: 24,
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                    },
                    [768]: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    },
                    [525]: {
                        spaceBetween: 10,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    [300]: {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                    }
                }}
            >
                {currHouses.map(report => (
                    <SwiperSlide key={report.id}>
                        <HouseCard report={report} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Row className={'footer mt-auto py-3 ' + styles.footer}>
                <span>© 2024 Homescore</span> | <Link to={DOCS_TERMS_URL}>Terms of Service</Link> |{' '}
                <Link to={DOCS_PRIVACY_URL}>Privacy Policy</Link>
            </Row>
        </Col>
    )
}

HousesView.propTypes = {
    reports: PropTypes.array.isRequired,
    sort_key: PropTypes.any,
}