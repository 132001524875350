import PropTypes from 'prop-types';
import { useState } from 'react';

import styles from './index.module.scss';

import logo from '@/assets/headerLogo.svg';
import intro from '@/assets/openingImage.svg';
import Card, { CardType } from '@/components/Card';
import {Row, Col, Button, Img, Input} from '@/components/Layout';
import { ColorAccent } from '@/constants';

export const Authentication = ({ children, subtitle = "", seeEmail = true, seePassword = true, buttonType = "", onClick = () => { } }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async e => {
        e.preventDefault();
        onClick(email, password);
    };

    return (
        <Row className={`${styles.authentication_block} row-cols-2 p-2`}>
                <Col className={styles.intro_img}>
                    <Img src={intro} />
                </Col>
                <Col className={styles.main_content}>
                        <Card className={styles.formCard} type={CardType.autoBox}>
                            <Card.Header className={styles.header}>
                                <Col className={styles.titles}>
                                    <Img style={{ height: '60px' }} src={logo}></Img>
                                    <h1 className={styles.subtitle}>{subtitle}</h1>
                                </Col>
                            </Card.Header>
                            <Card.Body>
                                <form className={styles.form} onSubmit={onSubmit}>
                                    {seeEmail && (
                                        <Input
                                            className={styles.input}
                                            type="email"
                                            placeholder="E-mail"
                                            defaultValue={email}
                                            onChange={setEmail}
                                            name="email"
                                            id="inputEmail"
                                            required
                                        />
                                    )}
                                    {seePassword && (
                                        <Input
                                            className={styles.input}
                                            type="password"
                                            placeholder="Password"
                                            defaultValue={password}
                                            onChange={setPassword}
                                            id="inputPassword"
                                            required
                                        />
                                    )}
                                    {(seeEmail || seePassword) && (
                                        <Button colorAccent={ColorAccent.black} className={styles.button + " mt-5"} type="submit">
                                            {buttonType}
                                        </Button>
                                    )}
                                </form>
                                {children}
                            </Card.Body>
                        </Card>
                </Col>
        </Row>
    );
};

Authentication.propTypes = {
    children: PropTypes.node,
    subtitle: PropTypes.any.isRequired,
    buttonType: PropTypes.string,
    seeEmail: PropTypes.bool,
    seePassword: PropTypes.bool,
    onClick: PropTypes.func,
}