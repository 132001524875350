import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { track } from '@vercel/analytics/react';

import { dispatch } from '..';

import { UserAPI } from '@/api/user';

const initialState = {
    isLoggedIn: false,
    data: null,
    isInitialized: false,
};

/**
 * Try initializing user if it's logged in
 */
export const initializeUser = createAsyncThunk('user/initialize', async (_, { dispatch }) => {
    dispatch(setUserData(await UserAPI.me()));
    await UserAPI.setCSRF();
});

export const loginUser = createAsyncThunk('user/login', async ({ username, password }, { dispatch }) => {
    await UserAPI.setCSRF();
    await UserAPI.login(username, password);
    dispatch(setUserData(await UserAPI.me()));
    track('Returning User');
});

export const signupUser = createAsyncThunk('accounts/signup', async ({ email, password1 }, { dispatch }) => {
    await UserAPI.setCSRF();
    await UserAPI.register(email, password1);
    dispatch(setUserData(await UserAPI.me()));
    track('New User');
});

export const logoutUser = createAsyncThunk('user/logout', async (_, { dispatch }) => {
    try {
        await UserAPI.logout();
    } finally {
        dispatch(logout());
        track('User Logging Out')
    }
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: state => {
            state.isInitialized = true;
            state.isLoggedIn = false;
            state.data = null;
            localStorage.setItem('isLoggedIn', 'false');
        },
        setUserData: (state, { payload }) => {
            state.isInitialized = true;
            state.data = payload;
            state.isLoggedIn = true;
            localStorage.setItem('isLoggedIn', 'true');
        },
    },
});

export const { logout, setUserData } = userSlice.actions;

export const userReducer = userSlice.reducer;
