import PropTypes from 'prop-types';
import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';

export const ConditionRoute = ({isAllowed, redirectPath = '/', children}) => {
    if (!isAllowed) return <Navigate to={redirectPath} replace />;

    return children ? children : <Outlet />;
};

ConditionRoute.propTypes = {
    redirectPath: PropTypes.string,
    isAllowed: PropTypes.bool,
    children: PropTypes.node,
};
