import { ArrowsOutCardinal, Backpack, ChatsCircle, MagicWand, Plug, SwimmingPool, Sun, Waveform, Wrench } from '@phosphor-icons/react';
import { track } from '@vercel/analytics/react';
import { Chart as ChartJS, RadarController, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useSelector } from 'react-redux';

import styles from './Main.module.scss';

import { ReportAPI } from '@/api/report';
import { UserAPI } from '@/api/user';
import bed from '@/assets/icons/bed.svg';
import cap from '@/assets/icons/cap.svg';
import dollar from '@/assets/icons/dollar.svg';
import family from '@/assets/icons/family.svg';
import hourglass from '@/assets/icons/hourglass.svg';
import home from '@/assets/icons/house.svg';
import thinX from '@/assets/icons/thinXShape.svg';
import toilet from '@/assets/icons/toilet.svg';
import BigDollar from '@/assets/insights/3D_Dollar.svg';
import schoolBag from '@/assets/insights/backpack.svg';
import bathroom from '@/assets/insights/bathroom.svg';
import calendar from '@/assets/insights/calendar.svg';
import kingBed from '@/assets/insights/kingBed.svg';
import tape from '@/assets/insights/measuringTape.svg';
import house from '@/assets/insights/smallHouse.svg';
import Card, { CardType, CardLinkPosition } from '@/components/Card';
import { Flex, Col, Row } from '@/components/Layout';
import { ColorAccent } from '@/constants';
import { selectUserData } from '@/store/user/selectors';
import { BlockWithChat } from "@/views/PropertyInsights/mainComponents/blockWithChat/blockWithChat";
import { BlockWithGraph } from "@/views/PropertyInsights/mainComponents/blockWithGraph/blockWithGraph";
import { SlimCardItem } from "@/views/PropertyInsights/mainComponents/slimCardItem/slimCardItem";

ChartJS.register(RadarController, RadialLinearScale, PointElement, LineElement, Filler);
const THRESH_WEAK_FIT = parseFloat(process.env.REACT_APP_THRESH_WEAK_FIT);
const THRESH_PARTIAL_FIT = parseFloat(process.env.REACT_APP_THRESH_PARTIAL_FIT);

export const chartOptions = {
    layout: {
        padding: {
            left: 40,
            right: 40,
        },
    },
    scales: {
        r: {
            grid: {
                circular: true,
            },
            beginAtZero: true,
            pointLabels: {
                font: {
                    size: window.innerWidth <= 625 ? 10 : 16,
                    family: 'Poppins',
                    weight: 400,
                },
            },
            min: 0,
            max: 10,
            ticks: {
                stepSize: 2, // the number of step
            },
        },
    },
    elements: {
        line: {
            tension: 0.1,
            borderWidth: 2,
            borderCapStyle: 'round',
            borderJoinStyle: 'round',
            fill: true,
            borderColor: 'rgba(19, 171, 53, 0.54)',
            backgroundColor: 'rgba(19, 171, 53, 0.06)',
            shadowBlur: 3,
            shadowOffsetX: 3,
            shadowOffsetY: 10,
        },
        point: {
            radius: 0,
        },
    },
};

export const setPathColor = (score) => {
    if (score < 5) {
        return ({
            textColor: '#ab430a',
            pathColor: '#ab430a',
        });
    } else if (score < 8) {
        return ({
            textColor: '#2875ad',
            pathColor: '#2875ad',
        })
    }

    return ({
        textColor: '#0c6b21',
        pathColor: '#0c6b21',
    })
}

export const Body = ({ propertyId, report }) => {
    //данные, которые сформированы из данных, полученных запросом getPreferences (general preferences)
    const [chartData, setChartData] = useState(null);
    const [chartUserData, setChartUserData] = useState(null);
    const [mergeData, setMergeData] = useState({
        labels: [],
        datasets: []
    })
    const [preferences, setPreferences] = useState(null);
    const [userPreferences, setUserPreferences] = useState(null);
    const addressDetails = report?.details?.addressDetails;
    const [fit, setFit] = useState({
        label: "",
        style: styles.fit + " " + styles.strongFit,
    })
    const [bigDisplay, setBigDisplay] = useState(false);
    const [message, setMessage] = useState("");
    const user = useSelector(selectUserData);

    useEffect(() => {
        if (userPreferences && userPreferences.length === 8) {
            ReportAPI.getFitScore(propertyId).then(data => {
                if (data.fit_score < THRESH_WEAK_FIT) {
                    setFit({
                        label: "Weak Fit",
                        title_style: styles.fit + " " + styles.weakFit,
                        graph_outline: "#e4281b",
                        graph_background: "rgba(228, 40, 27, 0.06)",
                    })
                } else if (data.fit_score <= THRESH_PARTIAL_FIT) {
                    setFit({
                        label: "Partial Fit",
                        title_style: styles.fit + " " + styles.partialFit,
                        graph_outline: "#348BF0",
                        graph_background: "rgba(52, 139, 240, 0.06)",
                    })
                } else {
                    setFit({
                        label: "Strong Fit",
                        title_style: styles.fit + " " + styles.strongFit,
                        graph_outline: "#13ab35",
                        graph_background: "rgba(19, 171, 53, 0.06)",
                    })
                }
            })
        }
    }, [chartData, chartUserData, propertyId])

    useEffect(() => {
        ReportAPI.getPreferences(+propertyId).then(data => {
            let pref = data.filter(datum => datum.preference !== "Open Floor Plan");
            setPreferences(pref);
            setChartData({
                labels: pref.map(p => p.preference),
                datasets: [
                    {
                        label: 'Your Preferences',
                        data: data.map(p => p.score),
                    },
                ],
            });
        })

        UserAPI.getUserPreferences().then(data => {
            setUserPreferences(data);
            let prefs = [];
            if (data.length === 8) {
                prefs = data;
            }

            setChartUserData({
                labels: prefs.map(p => p.preference),
                datasets: [
                    {
                        label: 'Your Preferences',
                        data: prefs.map(p => p.score),
                    },
                ],
            });
        })
    }, [propertyId]);

    useEffect(() => {
        if (chartData && chartUserData) {
            setMergeData({
                labels: chartData.labels,
                datasets: [
                    {
                        label: 'General Preferences',
                        data: chartData.datasets[0].data,
                        borderColor: fit.graph_outline,
                        backgroundColor: fit.graph_background
                    },
                    {
                        label: 'User Preferences',
                        data: chartUserData.datasets[0].data,
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.06)'
                    }
                ]
            })
        }
    }, [chartData, chartUserData, fit.graph_outline])

    const category = {
        "Amenities": <SwimmingPool style={{ display: 'inline' }} />,
        "Curb Appeal": <MagicWand style={{ display: 'inline' }} />,
        "Energy Efficiency": <Plug style={{ display: 'inline' }} />,
        "Good Schools": <Backpack style={{ display: 'inline' }} />,
        "Home Size": <ArrowsOutCardinal style={{ display: 'inline' }} />,
        "Maintenance": <Wrench style={{ display: 'inline' }} />,
        "Natural Lighting": <Sun style={{ display: 'inline' }} />,
        "Noise Level": <Waveform style={{ display: 'inline' }} />,
    }

    const setupDisplay = (type) => {
        setBigDisplay(true)

        if (type === "year") {
            setChatDisplay({
                name: 'Year Built',
                description: "When you think about the year a home was built, you're not just considering its age; you're also evaluating potential maintenance requirements, understanding its historical context, appreciating any unique architectural features it might have, and recognizing how it fits into the broader evolution of housing styles and construction practices.",
                icon: hourglass,
                image: calendar
            })
            return "Year Built";
        }

        if (type === "size") {
            setChatDisplay({
                name: 'Home Size',
                description: "When considering the importance of home size, you'll delve into its practical, psychological, economic, and social aspects, exploring how it affects your daily life, mental well-being, finances, social interactions, and environmental footprint",
                icon: thinX,
                image: tape,
            })
            return "Home Size";
        }

        if (type === "school") {
            setChatDisplay({
                name: 'Schools',
                description: "When you consider schools based on their proximity to your home, you're looking at commute convenience, neighborhood safety, community engagement opportunities, property values, and most importantly, ensuring your children have access to quality education and a supportive learning environment.",
                icon: cap,
                image: schoolBag,
            })
            return "Schools";
        }

        if (type == "home overview") {
            setChatDisplay({
                name: 'Home Overview',
                description: "When you are considering a home, consider aspects like  amenities, schools, home size, curb appeal, energy efficiency, schools, natural lighting, noise level, maintenance among other things",
                icon: home,
                image: house
            })
            return "Home Overview";
        }
    }

    const [chat, setChat] = useState(false);
    const [chatId, setChatId] = useState(null);
    const [chatTaskId, setChatTaskId] = useState(null);
    const [isWaitingForBotResponse, setIsWaitingForBotResponse] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const initialBotMessage = "Hey there! I am the HomeScore Assistant! I'm here to help answer your questions ";
    const [userEmails, setUserEmails] = useState(Object());
    const [loading, setLoading] = useState('.');
    const chatContainerRef = useRef(null);
    const bigBoxRef = useRef(null);

    const [chatDisplay, setChatDisplay] = useState({
        name: '',
        description: '',
        icon: null,
        image: null,
    })

    const handleMessage = (e, event) => {
        setMessage(event.target.value);
    }

    const [currCardType, setCurrCardType] = useState("");

    const handleChat = (report_id, type_name) => {
        setCurrCardType(type_name);
        ReportAPI.fetchOrCreateChat(report_id, type_name)
            .then((data) => {
                if (data?.messages?.length > 0) {
                    setChatId(data.id)
                    setChatMessages(data.messages)
                } else {
                    setChatId(data.id)
                    setChatMessages([{
                        content: initialBotMessage + `about ${type_name.toLowerCase()}. How can I help?`,
                        message_type: "AI",
                        issue: type_name
                    }]);
                }
            })
    }

    const handleMessageSend = async () => {
        const newUserMessage = { content: message, message_type: "HUMAN", issue: currCardType, user: user.id };
        setChatMessages([...chatMessages, newUserMessage]);
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        await ReportAPI.sendMessage(message, chatId, currCardType).then(data => {
            setChatTaskId(data.task_id)
            setMessage("")
            setIsWaitingForBotResponse(true);
        })
        track("Sent Chat", { location: 'insights' });
    }

    const handleKeyDown = async (event) => {
        // console.log("key pressed");
        if (event.key === 'Enter') {
            const newUserMessage = { content: message, message_type: "HUMAN", issue: currCardType, user: user.id };
            setChatMessages([...chatMessages, newUserMessage]);
            await ReportAPI.sendMessage(message, chatId, currCardType).then(data => {
                setChatTaskId(data.task_id)
                setMessage("")
                setIsWaitingForBotResponse(true);
            })
            track("Sent Chat", { location: 'insights' });
        }
    }

    useEffect(() => {
        let interval;
        if (isWaitingForBotResponse) {
            interval = setInterval(() => {
                setLoading((prevLoad) => {
                    // If there are already 3 dots, reset to one dot.
                    return prevLoad === '...' ? '.' : prevLoad + '.';
                });
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                ReportAPI.checkBotResponse(chatTaskId, chatId).then(data => {
                    if (data.state === "SUCCESS" || data.state === "FAIL") {
                        const newBotMessage = { content: data.result.content, message_type: "AI", issue: currCardType };
                        setChatMessages([...chatMessages, newBotMessage]);
                        setIsWaitingForBotResponse(false);
                        clearInterval(interval);
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isWaitingForBotResponse, chatTaskId, chatMessages]);


    useEffect(() => {
        const chatContainer = document.getElementById("chatmessage");
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, [chatMessages, chat]);


    const handleCard = async (type) => {
        const type_name = setupDisplay(type);
        track("Chat Box Opened", { location: "insights", subject: type_name })
        setChat(false);
        handleChat(report.id, type_name);
        if (bigBoxRef.current) {
            bigBoxRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const slimCardItems = [
        { id: 1, smallImg: dollar, bigImg: BigDollar, title: 'Price', value: report?.details?.price || '' },
        { id: 2, smallImg: bed, bigImg: kingBed, title: 'Beds', value: report?.details?.bedrooms || 0 },
        { id: 3, smallImg: toilet, bigImg: bathroom, title: 'Bath', value: report?.details?.bathrooms || 0 },
        { id: 4, smallImg: family, bigImg: house, title: 'Type', value: report?.details?.propertyTypeDimension || '' },
    ]

    return (
        <Col>
            <Row className={`${styles.slimCard} `}>
                {
                    slimCardItems.map(i => <SlimCardItem key={i.id} item={i} />)
                }
            </Row>
            <div ref={bigBoxRef} className={styles.graphCard}>
                {
                    bigDisplay ? <BlockWithChat chat={chat}
                        setChat={setChat}
                        message={message}
                        handleMessage={handleMessage}
                        handleKeyDown={handleKeyDown}
                        chatContainerRef={chatContainerRef}
                        chatMessages={chatMessages}
                        isWaitingForBotResponse={isWaitingForBotResponse}
                        loading={loading}
                        handleMessageSend={handleMessageSend}
                        chatDisplay={chatDisplay}
                        setBigDisplay={setBigDisplay}
                        handleCard={handleCard}
                        report={report}
                        addressDetails={addressDetails} />
                        :
                        <BlockWithGraph userPreferences={userPreferences}
                            fit={fit}
                            chartData={chartData}
                            preferences={preferences}
                            mergeData={mergeData}
                            handleCard={handleCard}
                            report={report}
                            addressDetails={addressDetails} />
                }
            </div>
            <br />
            <div className={styles.preferences_wrapper}>
                {preferences &&
                    preferences.map(p => (
                        <Card
                            type={CardType.smallBox}
                            key={p.preference}
                            className={styles.preferenceCard + " " + styles.prefBounds}
                            colorAccent={
                                p.score < 5 ? ColorAccent.orange : p.score < 8 ? ColorAccent.blue : ColorAccent.green
                            }
                        >
                            <Card.Header>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {category[p.preference]}
                                    <p style={{
                                        display: 'inline',
                                        marginLeft: '10px',
                                        marginBottom: '0'
                                    }}>{p.preference}</p>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Col className={styles.progressBarContainer}>
                                    <div className={styles.circleScore}>
                                        <CircularProgressbar
                                            value={p.score}
                                            maxValue={10}
                                            text={`${p.score}/10`}
                                            styles={buildStyles(setPathColor(p.score))}
                                        />
                                    </div>
                                    <div className={styles.rationale}>{p.rationale}</div>
                                </Col>
                            </Card.Body>
                        </Card>
                    ))}
            </div>
            <div className={'btn btn-primary ' + styles.fixed_chat_button} style={bigDisplay ? { 'display': 'none' } : {}}
                onClick={() => {
                    handleCard('home overview')
                }} onKeyDown={(e) => {
                    if (e.key === 'Enter') handleCard('home overview');
                }}>
                <ChatsCircle size={32} />
            </div>
        </Col>
    );
};

Body.propTypes = {
    propertyId: PropTypes.string,
    report: PropTypes.object,
};

export const Controls = props => <></>;
