import PropTypes from 'prop-types';
import React from "react";

import styles from './bigDisplayBlock.module.scss';

import send from "@/assets/icons/sendMessage.svg";
import {ChatComponent} from "@/components/ChatComponent/chatComponent";
import {Col, Row, Button, Img, Input} from "@/components/Layout";

const BigDisplayBlock = ({
                             children, className = '', action = () => {
    }, chat, ...props
                         }) => {

    return (
        <Col className={`${styles.bigDisplayContainer} ${className}`}>
            {children}
        </Col>
    )
}

BigDisplayBlock.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    action: PropTypes.func,
    chat: PropTypes.bool,
};

const Header = ({children, icon, className = '', chat, ...props}) => {
    return (
        <div className={`${styles.header} ${className}`} style={{height: chat ? "70%" : "50%"}}>
            <div style={chat ? {display: 'none'} : {padding: '15px'}}>
                <div className={styles.header_content_wrapper}>
                    <div className={styles.header_content}>
                        {children}
                    </div>
                    {icon && <Img src={icon} style={{width: "100px"}}/>}
                </div>
            </div>
        </div>
    );
};

Header.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    chat: PropTypes.bool,
};

BigDisplayBlock.Header = Header;

const Body = ({
                  children, className = '', priceBtnTitle, priceBtnStyles, chat, setChat, message
                  , handleMessage, handleKeyDown, closeCard, isMobileMini, chatContainerRef,
                  chatMessages, isWaitingForBotResponse, loading, handleMessageSend, withoutBodyAnimation, ...props
              }) => {
    return (
        <Row className={'align-items-stretch flex-grow-1 w-100'}>
            <div className={className} style={{height: chat ? "70%" : "50%"}}></div>
            <Col className={styles.chatBox} style={{marginTop: chat ? "60px" : "80px"}}>
                {
                    chat ? <ChatComponent chat={chat}
                                          setChat={setChat}
                                          chatContainerRef={chatContainerRef}
                                          chatMessages={chatMessages}
                                          isWaitingForBotResponse={isWaitingForBotResponse}
                                          loading={loading}
                                          message={message}
                                          handleMessage={handleMessage}
                                          handleMessageSend={handleMessageSend}
                                          handleKeyDown={handleKeyDown}/>
                        :
                        <Col className={`${withoutBodyAnimation ? styles.add_flex_class : styles.chatBoxAnimation2}`}>
                            <Col className={`${withoutBodyAnimation ? styles.add_flex_class : styles.chatBoxAnimation}`}>

                                <div className={priceBtnStyles}
                                     style={{display: withoutBodyAnimation ? 'none' : ''}}>
                                    {priceBtnTitle}
                                </div>

                                <Col className={`${styles.chatBoxContainer} ${!withoutBodyAnimation && styles.chatBoxWithBg}`}>

                                    <Col className={styles.chatOptions}>
                                        {children}
                                    </Col>

                                    <div className={styles.chat2}>
                                        <Input
                                            placeholder='Type your message here...'
                                            value={message}
                                            onClick={() => setChat(!chat)}
                                            onChange={handleMessage}
                                            onKeyDown={handleKeyDown}
                                            className={styles.message}/>
                                        <Img src={send} alt="Send"
                                             className={styles.send}
                                             onClick={() => setChat(!chat)}/>
                                    </div>

                                    <div className={`${styles.tag} ${chat ? styles.unseenTag : styles.seenTag}`}
                                         style={{display: withoutBodyAnimation ? 'none' : ''}}>
                                        <Button className={styles.tagBtn}
                                                style={{
                                                    fontSize: isMobileMini ? '13px' : '',
                                                    marginRight: !isMobileMini ? '10px' : ''
                                                }}
                                                onClick={closeCard}>
                                            {isMobileMini ? 'Close' : 'Close card'}
                                        </Button>
                                    </div>
                                </Col>
                            </Col>
                        </Col>
                }
            </Col>
        </Row>
    );
};

Body.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    priceBtnTitle: PropTypes.string,
    priceBtnStyles: PropTypes.string,
    chat: PropTypes.bool,
    setChat: PropTypes.func,
    message: PropTypes.string,
    handleMessage: PropTypes.func,
    handleKeyDown: PropTypes.func,
    closeCard: PropTypes.func,
    isMobileMini: PropTypes.bool,
    chatContainerRef: PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    chatMessages: PropTypes.array,
    isWaitingForBotResponse: PropTypes.bool,
    loading: PropTypes.string,
    handleMessageSend: PropTypes.func,
    withoutBodyAnimation: PropTypes.bool
};
BigDisplayBlock.Body = Body;

export default BigDisplayBlock;