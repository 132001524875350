import { Warning } from '@phosphor-icons/react';
import PropTypes from 'prop-types';

import styles from './index.module.scss'

import {Button, Col, Row, Modal} from '@/components/Layout';

export const WarnUpload = ({ isOpen, onClose = () => { }, onNext = () => { } }) => {
    return (
            <Modal isOpen={isOpen} onClose={onClose} title={"Upload Inspection Report"}>

                    <Col className={styles.contents_wrapper}>
                        <p className={styles.description}>Unlock personalized insights & cost estimates</p>
                        <Col className={styles.warning}>
                            <Row className={styles.price_block}>
                                <Warning style={{ display: 'inline', color: 'white' }} size={'96px'} />
                                <h1 className={styles.price_tag}>$14,000</h1>
                            </Row>
                            <p className={styles.tag}>The average amount buyers save by using their inspection report to negotiate an offer.</p>
                        </Col>
                        <Button className={styles.continueBtn} onClick={() => (onNext())}>Upload Report</Button>
                    </Col>

            </Modal>
    )
}

WarnUpload.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}