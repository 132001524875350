import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { ConditionRoute } from '@/components/ProtectedRoute';
import { selectIsLoggedIn, selectIsInitialized } from '@/store/user/selectors';
import { initializeUser } from '@/store/user/slice';
import { Comparison } from '@/views/Comparison';
import { EmailPassword } from '@/views/EmailPassword';
import { ForgotPassword } from '@/views/ForgotPassword';
import { Home } from '@/views/Home';
import { Login } from '@/views/Login';
import { Logout } from '@/views/Logout';
import { MyProperties } from '@/views/MyProperties';
import { Password } from '@/views/Password';
import { Profile } from '@/views/Profile';
import { PropertyInsights } from '@/views/PropertyInsights';
import { QuizSummary } from '@/views/QuizSummary';
import { Signup } from '@/views/Signup';

export const Router = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isInitialized = useSelector(selectIsInitialized);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(initializeUser());
    }, [dispatch]);

    return isInitialized ? (
        <Routes>
            <Route element={<ConditionRoute isAllowed={!isLoggedIn} />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Signup />} />
                <Route path="reset">
                    <Route path="" element={<ForgotPassword />} />
                    <Route path="confirmed" element={<EmailPassword />} />
                </Route>
                <Route path="quiz" element={<QuizSummary inApp={false} />} />
            </Route>

            <Route element={<ConditionRoute isAllowed={isLoggedIn} redirectPath="/register" />}>
                <Route path="" element={<Home />} />
                <Route path="my-properties">
                    <Route path="" element={<MyProperties />} />
                    <Route path="insights/:propertyId" element={<PropertyInsights />} />
                </Route>
                <Route path="summary" element={<QuizSummary />} />
                <Route path="comparison" element={<Comparison />} />
                <Route path="profile" element={<Profile />} />
                <Route path="password" element={<Password />} />
                <Route path="logout" element={<Logout />} />
            </Route>
        </Routes>
    ) : (
        ''
    );
};
