import { Menu, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import Gravatar from 'react-gravatar';
import { useSelector } from 'react-redux';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import styles from './index.module.scss';

import logo from '@/assets/headerLogo.svg';
import angleArrowDown from '@/assets/icons/angleArrowDown.svg';
import { Contents, Img, Icon, Row } from '@/components/Layout';
import { selectUserData } from '@/store/user/selectors';

const Header = ({ className = '' }) => {
    const user = useSelector(selectUserData);
    const navigate = useNavigate();
    const location = useLocation();
    let display_name = user.first_name ? (user.last_name ? (user.first_name + " " + user.last_name) : (user.first_name)) : user.username
    const currentTabClass = link => {
        return location.pathname.startsWith(link) ? ' ' + styles.current : '';
    };
    const currentLink = link => {
        return location.pathname.startsWith(link) ? location.pathname : link;
    };

    return (
        <header className={styles.header + ' ' + className} style={{ zIndex: 100 }}>
            <Contents className={styles.container}>
                <Img className={styles.logo} src={logo} onClick={() => navigate('/')} />

                {user ? (
                    <Row className={styles.tabs}>
                        <Link
                            className={styles.tab + currentTabClass('/my-properties')}
                            to={currentLink('/my-properties')}
                        >
                            My Properties
                        </Link>
                        <Link
                            className={styles.tab + currentTabClass('/comparison')}
                            to={currentLink('/comparison')}
                        >
                            Comparison
                        </Link>
                        <Link
                            className={styles.tab + currentTabClass('/comparison')}
                            to={currentLink('/summary')}
                        >
                            Personal Summary
                        </Link>
                    </Row>
                ) : null}

                {user ? (
                    <Row className={styles.profile}>
                        <Gravatar email={user.username} className={styles.profileImage} />
                        <div className={styles.profileData}>
                            <p className={styles.name}>{display_name}</p>
                            <p className={styles.status}>BRONZE</p>
                        </div>
                        <Menu as="div" className={styles.menu}>
                            <Menu.Button className={styles.openButton}>
                                <Icon src={angleArrowDown} style={{ fontSize: 36 }} />
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enterFrom={styles.closed}
                                enterTo={styles.open}
                                leaveTo={styles.closed}
                            >
                                <Menu.Items className={styles.items}>
                                    <Menu.Item className={styles.item}>
                                        <Link to="/profile">Profile</Link>
                                    </Menu.Item>
                                    <Menu.Item className={styles.item}>
                                        <Link to="/password">Change Password</Link>
                                    </Menu.Item>
                                    <Menu.Item className={styles.item}>
                                        <Link to="/logout">Logout</Link>
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </Row>
                ) : null}
            </Contents>
        </header>
    );
};

Header.propTypes = {
    className: PropTypes.string,
};

export default Header;
