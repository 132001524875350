import PropTypes from 'prop-types';

const numFormatter = new Intl.NumberFormat();
export const formatNumber = number => {
    return numFormatter.format(number);
};

export const wrapper = fn => {
    fn.propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
    };
    return fn;
};

export const firstOf = (v, ...rest) => (v !== undefined || rest.length === 0 ? v : firstOf(...rest));

export const getReportAddresses = report => {
    if (!report) return {};
    const addressDetails = report?.details?.address;

    let address2, address1;
    // if (addressDetails) {
    //     address2 = addressDetails.streetAddress;
    //     address1 = `${addressDetails.city}, ${addressDetails.state || addressDetails.community} ${addressDetails.zipcode
    //         }`;
    // } else {
    const splitAddress = report.address.split(',');
    address2 = splitAddress[0];
    address1 = splitAddress.slice(1).join(',');
    // }

    return {
        fullAddress: report?.address || '',
        address2: address2 || '',
        address1: address1 || '',
    };
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
};
