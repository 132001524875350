import PropTypes from 'prop-types';

import styles from './index.module.scss';

import {Button, Col, Modal} from '@/components/Layout';


export const Demo = ({
                         isOpen, onClose = () => {
    }, onNext = () => {
    }
                     }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title={"HomeScore Demo"}>
            <Col className={styles.contents_wrapper}>
                <p className={styles.description}>Discover insights, get cost estimates, and identify potential issues
                    in homes you&apos;re considering</p>
                <iframe
                    className={styles.video}
                    src='https://www.youtube.com/embed/wAtpWgtut2Q'
                    title='HomeScore Demo'
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                />
                <Button className={styles.continueBtn} onClick={() => {onNext()}}>Dismiss</Button>
            </Col>
        </Modal>
    )
}

Demo.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}


