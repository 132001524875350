// implement basic axio config for coockies auth and csrf token
import { track } from '@vercel/analytics/react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { dispatch } from '@/store';
import { logout } from '@/store/user/slice';

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

api.interceptors.response.use(
    response => response,
    error => {
        if (!error.response) {
            // console.log('Unexpected API error:', error);
            track("Error", { status: 500, message: "Unexpected error while communicating with the server. Please try again later." })
            toast.error('Unexpected error while communicating with the server. Please try again later.');
        } else if (error.response.status === 401 || error.response.status === 403) {
            track("Error", { status: error.response.status, message: error.response.data?.detail || error.message })
            dispatch(logout());
        } else {
            // console.log('API error:', error.response);
            toast.error(error.response.data?.detail || error.message || 'Error while communicating with the server.');
        }
        return Promise.reject(error);
    },
);
