import PropTypes from 'prop-types';
import React from "react";

import styles from './slimCardItem.module.scss';

import {Img} from "@/components/Layout";

export const SlimCardItem = ({item}) => {

    return(
        <div className={`${styles.card_item} col-6 col-md-3`} >
            <div className='row row-cols-2'>
                <div className='col col-7'>
                    <div className={'d-flex'}>
                        <Img src={item.smallImg} style={{ marginRight: '3%' }} />
                        <h2>{item.title}</h2>
                    </div>
                    <h3 className={'text-start'}>{`${item.value}`}</h3>
                </div>
                <div className='col col-5  align-self-center'>
                    <Img src={item.bigImg} className={'mw-100'}/>
                </div>
            </div>
        </div>
    )
}

SlimCardItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        smallImg: PropTypes.string,
        bigImg: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.string || PropTypes.number
    })
}