import PropTypes from "prop-types";
import React from "react";

import styles from './issueMenu.module.scss';

import { Col } from "@/components/Layout";

export const IssueMenu = ({issueType, tabClick, showBig, urgentIssues, moderateIssues, lowIssues}) => {

    const menuItems = [
        {
            id: 1,
            itemIssueType: 'urgent',
            title: 'Urgent Priority',
            description: 'Problems that pose an immediate threat to the safety & integrity of the home.'
        },
        {
            id: 2,
            itemIssueType: 'moderate',
            title: 'Moderate Priority',
            description: 'Significant repair or maintenance issues that may affect the functionality of the home.'
        },
        {
            id: 3,
            itemIssueType: 'low',
            title: 'Low Priority',
            description: 'Minor cosmetic issues that do not impact the safety or the functionality of the home.'
        },
    ]


    return(
        // <div className={styles.levelsMenu + " " + (showBig ? styles.levelsMenuWidth : '')}>
        <div className={styles.levelsMenu}>
            {
                menuItems.map(i => {
                    return <Col onClick={() => (tabClick(i.itemIssueType))}
                                   key={i.id}
                                   className={`${issueType === i.itemIssueType ? `${styles.current} ${styles[i.itemIssueType]}` : ''} ${styles.item}`}>
                                       <span className={styles.title}>
                                           <span className={styles.counter}>
                                               {i.itemIssueType === 'urgent' ? urgentIssues.length
                                                   : i.itemIssueType === 'moderate' ? moderateIssues.length
                                                       : i.itemIssueType === 'low' ? lowIssues.length : ''}
                                           </span>
                                           {i.title}
                                       </span>
                        <span className={styles.description}>{i.description}</span>
                    </Col>
                })
            }
        </div>
    )
}

IssueMenu.propTypes = {
    issueType: PropTypes.string,
    tabClick: PropTypes.func,
    showBig: PropTypes.bool,
    urgentIssues: PropTypes.array,
    moderateIssues: PropTypes.array,
    lowIssues: PropTypes.array
};