import PropTypes from "prop-types";
import React, {useState} from "react";
import Gravatar from "react-gravatar";

import styles from './chatComponent.module.scss';

import {UserAPI} from "@/api/user";
import botImg from "@/assets/icons/botImg.svg";
import send from "@/assets/icons/sendMessage.svg";
import xThin from "@/assets/icons/xThin.svg";
import {Button, Col, Img, Row, Icon, Input} from "@/components/Layout";
import {ColorAccent} from "@/constants";


export const ChatComponent = ({chat, setChat, chatContainerRef, chatMessages, isWaitingForBotResponse, loading, message,
                                  handleMessage, handleMessageSend, handleKeyDown}) => {
    const [userEmails, setUserEmails] = useState(Object());

    const getUserEmail = (user) => {
        if (user in userEmails) {
            return userEmails[user];
        } else {
            UserAPI.getUser(user).then(data => {
                setUserEmails({...userEmails, [user]: data.email});
                return data.email;
            })
        }
    }

    return(
        <Col className={styles.chat_wrapper} style={chat ? {} : {display: 'none'}}>
            <Row className={styles.chat_header}>
                <h2 className={styles.chatHeader}>HomeScore AI Chatbot</h2>
                <Button className={styles.closeBtn} colorAccent={ColorAccent.white} onClick={() => setChat(false)}>
                    <Icon src={xThin} size={24}/>
                </Button>
            </Row>
            {/* chat start */}
            <div id="chatmessage" ref={chatContainerRef} className={styles.chatMessages}>
                {chatMessages.map((msg, index) => (
                    <div key={index}
                         className={msg.message_type === "AI" ? styles.botMessage : styles.userMessage}
                         style={{
                             justifyContent: msg.message_type === "AI" ? "" : "end",
                             flexDirection: msg.message_type === "AI" ? "" : "row-reverse"
                         }}>
                        <div className={msg.message_type === "AI" ? styles.botIcon : styles.userIcon}>
                            {msg.message_type === "AI" ?
                                <Img src={botImg} className={styles.profileImage}/> :
                                <Gravatar email={getUserEmail(msg.user)}
                                          className={styles.profileImage}/>}
                        </div>
                        <div className={styles.messageBubble}
                             style={{backgroundColor: msg.message_type === "AI" ? "#EEF0F6" : "#D0E6FF"}}>
                            <p>{msg.content}</p>
                        </div>
                    </div>
                ))}
                <div
                    style={isWaitingForBotResponse ? {} : {display: 'none'}}
                    className={styles.botMessage}>
                    <div className={styles.botIcon}>
                        <Img src={botImg} className={styles.profileImage}/>
                    </div>
                    <div className={styles.messageBubble}
                         style={{backgroundColor: "#EEF0F6"}}>
                        {loading}
                    </div>
                </div>
            </div>
            {/* chat end */}

            <div className={styles.chat1}>
                <Input placeholder='Type your message here...'
                       autoFocus
                       value={message}
                       onChange={handleMessage}
                       onKeyDown={handleKeyDown}
                       className={styles.message}/>
                <Img src={send} alt="Send" className={styles.send}
                     onClick={() => handleMessageSend()}/>
            </div>
        </Col>
    )
}

ChatComponent.propTypes = {
    chat: PropTypes.bool,
    setChat: PropTypes.func,
    chatContainerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    chatMessages: PropTypes.array,
    isWaitingForBotResponse: PropTypes.bool,
    loading: PropTypes.string,
    message: PropTypes.string,
    handleMessage:PropTypes.func,
    handleMessageSend: PropTypes.func,
    handleKeyDown: PropTypes.func,
};