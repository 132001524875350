import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import styles from './index.module.scss';

import { Authentication } from "@/components/Authentication";
import {Row} from "@/components/Layout";
import { signupUser } from '@/store/user/slice';

export const Signup = () => {
    const dispatch = useDispatch();
    const signin = async (email, password) => {
        dispatch(signupUser({ email, password1: password }));
    }

    return (
        <Authentication subtitle="Get Started!" buttonType="Sign Up" onClick={signin}>
            <Row className={styles.links_wrapper}>
                <Link to="/login" className={styles.link}>
                    Have an account?
                </Link>
            </Row>
        </Authentication>
    )
}