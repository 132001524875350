import {Dialog} from "@headlessui/react";
import {useCallback, useEffect, useState} from "react";

import styles from './layout.module.scss';

import xThin from "@/assets/icons/xThin.svg";
import Header from '@/components/Header';
import {ColorAccent} from "@/constants";
import {wrapper} from '@/utils';


export const Contents = wrapper(({children, className = '', ...props}) => (
    <div className={styles.mainContents + ' ' + className} {...props}>
        {children}
    </div>
));

export const Layout = wrapper(({children, className = '', ...props}) => (
    <div className={styles.layoutBox + ' ' + className} {...props}>
        <Header />
        <main className={styles.main}>{children}</main>
    </div>
));

export const Flex = wrapper(({children, className = '', ...props}) => (
    <div className={styles.flexContainer + ' ' + className} {...props}>
        {children}
    </div>
));

export const Row = wrapper(({children, className = '', ...props}) => (
    <div className={styles.row + ' ' + className} {...props}>
        {children}
    </div>
));

export const Col = wrapper(({children, className = '', ...props}) => (
    <div className={styles.col + ' ' + className} {...props}>
        {children}
    </div>
));

export const Button = wrapper(({children, className = '', colorAccent = ColorAccent.black, ...props}) => (
    <button className={`${styles.btn} ${className} accent-${colorAccent}`} {...props}>
        {children}
    </button>
))

export const Icon = wrapper(({src, className = '', style = {}, size = null, ...props}) => (
    <img
        src={src}
        className={className + ' ' + 'icon'}
        {...props}
        style={{height: '1em', minWidth: '1em', fontSize: size || undefined, ...style}}
    />
))

export const Input = wrapper(({className = '', onChange = null, ...props}) => {
    const onChangeWrapper = onChange ? e => onChange(e.target.value, e) : undefined;

    return <input {...props} className={className} onChange={onChangeWrapper}/>;
})

export const Modal = wrapper(({isOpen = true, onClose = () => { }, children, className = '', title = null, text = "", ...props}) => {

    return (
        <Dialog as="div" className={styles.dialog + ' ' + className} open={isOpen} onClose={onClose}>
            <Dialog.Panel className={styles.panel}>
                {text !== "" && <>{text}</>}
                {title && <Dialog.Title className={styles.title}>{title}</Dialog.Title>}
                <Button colorAccent={ColorAccent.white} className={styles.closeBtn} onClick={onClose}>
                    <Icon src={xThin} size={24} />
                </Button>
                {children}
            </Dialog.Panel>
        </Dialog>
    )
})

export const Img = wrapper(({src, className = '', preloadSrc = null, errorSrc = null, ...props}) => {
    const [imgSrc, setImgSrc] = useState(preloadSrc);
    const [imgState, setImgState] = useState('loading');

    const styles = {};

    const onLoad = useCallback(() => {
        setImgSrc(src);
        setImgState('loaded');
    }, [src]);

    const onError = useCallback(() => {
        errorSrc && setImgSrc(errorSrc);
        setImgState('failed');
    }, []);

    useEffect(() => {
        const img = new Image();
        img.onload = onLoad;
        img.onerror = onError;
        img.src = src;
    }, [src]);


    return <img src={imgSrc} className={styles[imgState] + ' ' + className} {...props} />;
})