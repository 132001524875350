import PropTypes from 'prop-types';
import {useEffect, useState} from "react";

import styles from './Noise.module.scss';
import {Row, Button, Img, Col, Modal} from '../Layout';

import {UserAPI} from '@/api/user';
import LawnMower from '@/assets/survey/lawnMower.svg';

export const Noise = ({
                          isOpen, onClose = () => {
    }, onNext = () => {
    }, preference
                      }) => {
    useEffect(() => {
        if (preference[questions.pref]) {
            setQuestions({...questions, response: preference[questions.pref]})
        }
    }, [preference])

    const [questions, setQuestions] = useState({
        title: "Noise Level",
        pref: "Noise Level",
        question: "What's the most amount of noise you can tolerate in your neighborhood?",
        choices: [
            {
                value: '8',
                name: <div><b style={{fontWeight: '500'}}>Very Quiet:</b> Leaves rustling outside</div>,
            },
            {
                value: '6',
                name: <div><b style={{fontWeight: '500'}}>Quiet:</b> Cars driving by your home</div>,
            },
            {
                value: '4',
                name: <div><b style={{fontWeight: '500'}}>Moderate:</b> Neighbor&apos;s lawn mower</div>,
            },
            {
                value: '2',
                name: <div><b style={{fontWeight: '500'}}>Loud:</b> Street Construction</div>,
            },
        ],
        response: '',
    });

    const handleResponse = (e) => {
        e.preventDefault();
        setQuestions({...questions, response: e.target.value});
    }

    const goNext = () => {
        if (questions.response !== '') {
            const body = {
                "score": parseInt(questions.response),
                "preference": "Noise Level"
            }
            UserAPI.setUserPreferences(body);
            onNext();
        }
    }

    const superscript = <>
        <p style={{display: 'inline', fontWeight: '600', fontSize: '24px', fontFamily: 'Poppins'}}>1</p>
        <p style={{display: 'inline', fontFamily: 'Poppins'}}>/8</p>
    </>

    return (
            <Modal isOpen={isOpen} onClose={onClose} title={questions.title} text={superscript}>
                <Col className={styles.contents_wrapper}>
                    <p className={styles.description}>{questions.question}</p>
                    <Row className={styles.main_content}>
                        <Img className={styles.image} src={LawnMower}/>
                        <Col className={styles.questions_wrapper}>
                            {questions.choices.map((choice) => (
                                <Row key={choice.value} className={styles.mcq}>
                                    <Button
                                        className={questions.response === choice.value ? styles.choose : styles.chose}
                                        value={choice.value}
                                        onClick={handleResponse}
                                    />
                                    <p>{choice.name}</p>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    <Button className={styles.continueBtn} onClick={goNext}>Save & Continue</Button>
                </Col>
            </Modal>
    )
}

Noise.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    preference: PropTypes.any,
}