import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';

import { Authentication } from '@/components/Authentication';
import {Row} from "@/components/Layout";
import { loginUser } from '@/store/user/slice';

const { REACT_APP_FORGOT_PASSWORD_URL } = process.env;

export const Login = () => {
    const dispatch = useDispatch();

    const login = (email, password) => {
        dispatch(loginUser({ username: email, password }));
    };

    return (
        <Authentication subtitle='Welcome back!' buttonType='Log In' onClick={login}>
            <Row className={styles.links_wrapper}>
                <Link to={'/reset'} className={styles.link}>
                    Forgot password?
                </Link>
                <Link to={'/register'} className={styles.link}>
                    Don&apos;t have an account?
                </Link>
            </Row>
        </Authentication>
    );
};
