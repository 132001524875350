import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';;
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Quiz.module.scss';
import { Modal, Input, Button } from '../Layout';

import { UserAPI } from '@/api/user';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

export const Quiz = ({ isOpen, onClose = () => { } }) => {
    const [curr_section, setCurr_section] = useState(0);
    const [curr_question, setCurr_question] = useState(0);
    const [in_ui_questions, setIn_ui_questions] = useState(false);
    const [base_quiz, setBaseQuiz] = useState(null);
    const [recording, setRecording] = useState("");
    const [waiting, setWaiting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetch = async () => {
            const resp = await UserAPI.getBaseQuiz();
            setBaseQuiz(resp);
        }
        fetch();
    }, [])

    useEffect(() => {
        if (base_quiz) {
            if (curr_question === base_quiz[curr_section]?.questions.length) {
                setCurr_question(0);
                setCurr_section(curr_section + 1);
                setIn_ui_questions(false);
            }
        }
    }, [curr_question])

    useEffect(() => {
        if (base_quiz) {
            if (curr_section === base_quiz.length) {
                setCurr_question(0);
                setCurr_section(0);
                setIn_ui_questions(false);
                onClose();
                location.reload();
                // navigate('/summary');
            }
        }
    }, [curr_section])

    // Mic Recording
    const [isListening, setIsListening] = useState(false);

    useEffect(() => {
        handleListen();
    }, [isListening]);

    const handleListen = () => {
        if (isListening) {
            mic.start();
            mic.onend = () => {
                mic.start();
            };
        } else {
            mic.stop();
            mic.onend = () => {
            };
        }
        mic.onstart = () => {
        };

        mic.onresult = (event) => {
            const transcript = Array.from(event.results)
                .map((result) => result[0])
                .map((result) => result.transcript)
                .join("");
            setRecording(transcript);
            mic.onerror = (event) => {
                console.log(event.error);
            };
        };
    };

    const closeCard = () => {
        setIsListening(false);
        onClose();
    }

    const enterUI = async () => {
        setWaiting(true);
        if (isListening) {
            setIsListening(false);
        }
        // console.log(recording);
        const body = {
            "section_index": curr_section,
            "section_response": recording,
        }
        const resp = await UserAPI.processSection(body);
        setWaiting(false);
        setBaseQuiz(prevData => {
            return prevData.map(item => {
                if (item.id === curr_section + 1) {
                    return {
                        ...item,
                        questions: resp["not_satisfied_questions"]
                    };
                }
                return item;
            });
        });

        if (resp["not_satisfied_questions"].length === 0) {
            setCurr_section(curr_section + 1);
        } else {
            setIn_ui_questions(true);
        }
    }

    const onNext = () => {
        if (response) {
            handleResponse()
            setResponse(null);
            setCurr_question(curr_question + 1);
        }
    }

    const [response, setResponse] = useState(null);
    const [rank, setRank] = useState([]);

    useEffect(() => {
        if (base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "RANK") {
            let temp = []
            base_quiz[curr_section]?.questions[curr_question]?.options.map((options, key) => {
                temp.push({ name: options, id: key + 1 })
            })
            setRank(temp)
        }

        if (base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "SLIDER") {
            setResponse(base_quiz[curr_section]?.questions[curr_question]?.min_max[0])
        }

    }, [curr_question, in_ui_questions])

    const handleResponse = async () => {
        const response1 = await UserAPI.getSectionResponse();
        const section_id = response1.find(data => data.section === (curr_section + 1));

        const body = {
            "question_id": base_quiz[curr_section]?.questions[curr_question]?.id,
            "section_response_id": section_id.id,
            "response": response,
        }

        const response2 = await UserAPI.questionResponse(body)
        // console.log(response2)
    }

    const handleCheckboxChange = (option) => {
        setResponse((prevResponse) => {
            if (prevResponse === null) {
                // Initialize the response as an array with the first selected option
                return [option];
            }

            // Toggle the option in the array
            return prevResponse.includes(option)
                ? prevResponse.filter((selectedOption) => selectedOption !== option)
                : [...prevResponse, option];
        });
    };

    const handleSlideChange = (event) => {
        setResponse(event.target.value);
    }

    const incrementRank = (name) => {
        setRank((prevItems) => {
            const sortedItems = [...prevItems].sort((a, b) => a.id - b.id);
            const index = sortedItems.findIndex(item => item.name === name);

            if (index > 0) {
                const itemToMoveUp = sortedItems[index];
                const itemAbove = sortedItems[index - 1];
                const newItems = sortedItems.map(item =>
                    item.name === name
                        ? { ...item, id: itemAbove.id }
                        : item.name === itemAbove.name
                            ? { ...item, id: itemToMoveUp.id }
                            : item
                );
                return newItems;
            }
            return prevItems;
        });
    };

    const decrementRank = (name) => {
        setRank((prevItems) => {
            const sortedItems = [...prevItems].sort((a, b) => a.id - b.id);
            const index = sortedItems.findIndex(item => item.name === name);

            if (index < sortedItems.length - 1) {
                const itemToMoveDown = sortedItems[index];
                const itemBelow = sortedItems[index + 1];
                const newItems = sortedItems.map(item =>
                    item.name === name
                        ? { ...item, id: itemBelow.id }
                        : item.name === itemBelow.name
                            ? { ...item, id: itemToMoveDown.id }
                            : item
                );
                return newItems;
            }
            return prevItems;
        });
    };

    const sortedItems = [...rank].sort((a, b) => a.id - b.id);

    useEffect(() => {
        const sorted = [...rank].sort((a, b) => a.id - b.id);
        const resp = sorted.map(item => item.name);
        setResponse(resp);
    }, [rank])

    return (
        <div>
            <Modal title={base_quiz && (base_quiz[curr_section]?.title)} isOpen={isOpen} onClose={closeCard}>
                <div className={styles.content_container}>
                    <div className={styles.contents}>
                        {
                            in_ui_questions ?
                                (
                                    <>
                                        <h3 className={styles.description}>
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.prompt}
                                        </h3>
                                        <div>
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "TEXT" && (
                                                <Input
                                                    className={styles.input}
                                                    type="text"
                                                    placeholder="Type here"
                                                    name="response"
                                                    defaultValue={response || ""}
                                                    id="inputText"
                                                    onChange={setResponse}
                                                />
                                            )}
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "RADIO" && (
                                                <>
                                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.options.map((option, key) => (
                                                        <div className="form-check" key={key}>
                                                            <input className="form-check-input" type="radio" name={curr_question} id={option} value={option} onChange={() => { setResponse(option) }} checked={response === option} />
                                                            <label className="form-check-label">
                                                                {option}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "RANK" && (
                                                <ul>
                                                    {sortedItems.map(item => (
                                                        <li key={item.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                                            <span style={{ marginRight: '8px' }}>{item.name} - Rank: {item.id}</span>
                                                            <button onClick={() => incrementRank(item.name)} style={{ marginRight: '4px' }}>+</button>
                                                            <button onClick={() => decrementRank(item.name)}>–</button>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "SLIDER" && (
                                                <Box sx={{ width: 600 }}>
                                                    <Slider
                                                        defaultValue={base_quiz && parseInt(base_quiz[curr_section]?.questions[curr_question]?.min_max[0])}
                                                        sx={{
                                                            color: '#2EB04A',
                                                            height: 12,
                                                            '& .MuiSlider-track': {
                                                                border: 'none',
                                                            },
                                                            '& .MuiSlider-thumb': {
                                                                height: 29,
                                                                width: 29,
                                                                backgroundColor: '#fff',
                                                                border: '7px solid #0C6B21',
                                                                '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                                                    boxShadow: 'inherit',
                                                                },
                                                                '&::before': {
                                                                    display: 'none',
                                                                },
                                                            },
                                                            '& .MuiSlider-rail': {
                                                                opacity: 0.5,
                                                                boxShadow: 'inset 0px 0px 4px -2px #000',
                                                                backgroundColor: '#EEF0F6',
                                                            },
                                                        }}
                                                        aria-label="slider"
                                                        valueLabelDisplay="off"
                                                        step={1}
                                                        marks
                                                        min={base_quiz && parseInt(base_quiz[curr_section]?.questions[curr_question]?.min_max[0])}
                                                        max={base_quiz && parseInt(base_quiz[curr_section]?.questions[curr_question]?.min_max[1])}
                                                        onChange={handleSlideChange}
                                                    />
                                                </Box>
                                            )}
                                            {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.input_type === "CHECKBOX" && (
                                                <>
                                                    {base_quiz && base_quiz[curr_section]?.questions[curr_question]?.options.map((option, key) => (
                                                        <div className="form-check" key={key}>
                                                            <input className="form-check-input" type="checkbox" name="example" id={option} value={option} onChange={() => handleCheckboxChange(option)} />
                                                            <label className="form-check-label">
                                                                {option}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </div>
                                        <Button onClick={() => { onNext() }} aria-label="in_quiz" className={styles.continueBtn}>Next</Button>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                            {base_quiz && base_quiz[curr_section]?.cues.map((cue, index) => (
                                                <li key={index}>
                                                    <p>{cue}</p>
                                                </li>
                                            ))}
                                        </ul>
                                        <button type="button" className={isListening ? "btn btn-danger my-4" : "btn btn-primary my-4"} onClick={() => setIsListening((prevState) => !prevState)}>{isListening ? "Stop Recording" : "Record"}</button>
                                        <Button onClick={() => { if (!waiting) enterUI() }} aria-label="in_quiz" className={styles.continueBtn}>{waiting ? "Thinking..." : "Next"}</Button>
                                    </>
                                )
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}

Quiz.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}