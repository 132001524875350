import { track } from "@vercel/analytics/react";
import { useState } from "react";
import { toast } from "react-toastify";

import styles from './index.module.scss';

import { UserAPI } from "@/api/user";
import {Col, Layout, Button, Input} from "@/components/Layout";

export const Password = () => {
    const [password, setPassword] = useState({
        curr_password: "",
        new_password: "",
        repeat: "",
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password.new_password === password.curr_password) {
            toast.warn("New password is the same as the old");
            track("Password Reset", { status: "Failed", message: "Same password" });
        } else if (password.new_password !== password.repeat) {
            toast.warn("New password does not match");
            track("Password Reset", { status: "Failed", message: "Unmatching passwords" });
        } else {
            await UserAPI.changePassword(password.curr_password, password.new_password, password.repeat);
            toast.success("Password changed");
            track("Password Reset", { status: "Success", message: "Success" });
        }
    }

    const handleChange = (e, event) => {
        setPassword({ ...password, [event.target.name]: event.target.value });
    }

    return (
        <Layout className={styles.layout}>
            <Col className={styles.card_wrapper}>
                <div className={"card mx-auto " + styles.card}>
                    <div className="card-body">
                        <h1>Password Reset</h1>
                        <form className={styles.data_card} onSubmit={handleSubmit}>
                            <div className={styles.profile_data}>
                                <label>Current Password</label>
                                <Input className={styles.input} type="password" value={password.curr_password} name="curr_password" onChange={handleChange} required />
                            </div>
                            <div className={styles.profile_data}>
                                <label>New Password</label>
                                <Input className={styles.input} type="password" value={password.new_password} name="new_password" onChange={handleChange} required />
                            </div>
                            <div className={styles.profile_data}>
                                <label>New Password &#40;Again&#41;</label>
                                <Input className={styles.input} type="password" value={password.repeat} name="repeat" onChange={handleChange} required />
                            </div>
                            <Button type="submit" className={styles.save_btn}>Save Password</Button>
                        </form>
                    </div>
                </div>
            </Col>
        </Layout>
    )
}