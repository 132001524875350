import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

import styles from './Noise.module.scss';
import {Row, Button, Img, Col, Modal} from '../Layout';

import { UserAPI } from '@/api/user';
import backpack from '@/assets/survey/backpack.svg';

export const School = ({ isOpen, onClose = () => { }, onNext = () => { }, preference }) => {
    useEffect(() => {
        if (preference[questions.pref]) {
            setQuestions({ ...questions, response: preference[questions.pref] })
        }
    }, [preference])

    const [questions, setQuestions] = useState({
        title: "Schools",
        pref: "Good Schools",
        question: "When considering a new home, how important is it to you that it is located near good schools?",
        choices: [
            {
                value: '8',
                name: <div>I need the top schools near me</div>,
            },
            {
                value: '6',
                name: <div>I can make a trip for a good school</div>,
            },
            {
                value: '4',
                name: <div>Zone schools are appreciated</div>,
            },
            {
                value: '2',
                name: <div>Any school works for me</div>,
            },
        ],
        response: '',
    });

    const handleResponse = (e) => {
        e.preventDefault();
        setQuestions({ ...questions, response: e.target.value });
    }

    const goNext = () => {
        if (questions.response !== '') {
            const body = {
                "score": parseInt(questions.response),
                "preference": "Good Schools"
            }
            UserAPI.setUserPreferences(body);
            onNext();
        }
    }

    const superscript = <>
        <p style={{ display: 'inline', fontWeight: '600', fontSize: '24px', fontFamily: 'Poppins' }}>7</p>
        <p style={{ display: 'inline', fontFamily: 'Poppins' }}>/8</p>
    </>

    return (
            <Modal isOpen={isOpen} onClose={onClose} title={questions.title} text={superscript}>
                    <Col className={styles.contents_wrapper}>
                        <p className={styles.description}>{questions.question}</p>
                            <Row className={styles.main_content}>
                                <Img className={styles.image} src={backpack} />
                                <Col className={styles.questions_wrapper}>
                                    {questions.choices.map((choice) => (
                                        <Row key={choice.value} className={styles.mcq}>
                                            <Button
                                                className={questions.response === choice.value ? styles.choose : styles.chose}
                                                style={{backgroundColor: '#FB8818'}}
                                                value={choice.value}
                                                onClick={handleResponse}
                                            />
                                            <p>{choice.name}</p>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        <Button className={styles.continueBtn} onClick={goNext}>Save & Continue</Button>
                    </Col>
            </Modal>
    )
}

School.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    preference: PropTypes.any,
}