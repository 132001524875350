import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Router } from './router';

const App = () => {
    return (
        <div className="App">
            <BrowserRouter>
                <Router />
            </BrowserRouter>
            <ToastContainer />
        </div>
    );
};

export default App;
