import { track } from "@vercel/analytics/react";
import { useState } from "react";
import Gravatar, { displayName } from "react-gravatar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import styles from './index.module.scss';


import { UserAPI } from "@/api/user";
import shield from '@/assets/icons/shield.svg';
import {Col, Layout, Button, Input} from "@/components/Layout";
import {useMobileMini} from "@/hooks/useAdaptives";
import { selectUserData } from '@/store/user/selectors';
import { initializeUser } from "@/store/user/slice";

const DOCS_PRIVACY_URL = process.env.REACT_APP_DOCS_PRIVACY_URL;
const DOCS_TERMS_URL = process.env.REACT_APP_DOCS_TERMS_URL;

export const Profile = () => {
    const [user, setUser] = useState({
        firstname: useSelector(selectUserData).first_name,
        lastname: useSelector(selectUserData).last_name,
        email: useSelector(selectUserData).username,
    })

    const [enabled, setEnabled] = useState(false);

    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await UserAPI.setProfile(user.email, user.firstname, user.lastname);
        dispatch(initializeUser());
        toast.success("Changes have been saved");
        track("Profile changed");
    }

    const handleChange = (e, event) => {
        setUser({ ...user, [event.target.name]: event.target.value });
    }

    const isMobileMini = useMobileMini();

    return (
        <Layout className={styles.layout}>
            <Col className={styles.card_wrapper}>
                <div className={"card mx-auto " + styles.card}>
                    <div className="card-body w-75">
                        <Gravatar email={user.email} size={isMobileMini ? 100 : 200} className={styles.profile_pic} />
                        <div className={styles.data_card}>
                            <form onSubmit={handleSubmit}>
                                <div className={styles.profile_data}>
                                    <label>First Name</label>
                                    <Input className={styles.input} type="text" value={user.firstname} name="firstname" onChange={handleChange} />
                                </div>
                                <div className={styles.profile_data}>
                                    <label>Last Name</label>
                                    <Input className={styles.input} type="text" value={user.lastname} name="lastname" onChange={handleChange} />
                                </div>
                                <div className={styles.profile_data}>
                                    <label>Email Address</label>
                                    <Input className={styles.input} type="text" value={user.email} name="email" onChange={handleChange} />
                                </div>
                                <Button type="submit" className={styles.save_btn}>Save Profile</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </Col>
        </Layout >
    )
}