import {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {logoutUser} from '@/store/user/slice';

export const Logout = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(logoutUser());
    }, [dispatch]);
    return null;
};
