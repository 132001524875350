import PropTypes from 'prop-types';

import styles from './Complete.module.scss';

import check from '@/assets/survey/checkmark.svg';
import {Button, Col, Img, Modal} from "@/components/Layout";


export const Complete = ({ isOpen, onClose = () => { }, onNext = () => { } }) => {
    const goNext = () => {
        onNext();
    }

    return (
            <Modal isOpen={isOpen} onClose={onClose} title={"Survey Complete"}>
                    <Col className={styles.contents_wrapper}>
                        <p className={styles.description}>{"Each home's insights are now customized for you!"}</p>
                        <Img className={styles.image} src={check} />
                        <Button className={styles.continueBtn} onClick={goNext}>Continue</Button>
                    </Col>

            </Modal>
    )
}

Complete.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
}