import PropTypes from 'prop-types';
import React from 'react';

export const Details = ({ details }) => {
    return (
        <div className='my-4' style={details ? {} : { display: 'none' }}>
            {details?.length !== 0 && (
                <>
                    <h5>Details</h5>
                    {details?.map((detail, index) => (
                        <p key={index} dangerouslySetInnerHTML={{ __html: detail }}></p>
                    ))}
                </>
            )}
        </div>
    );
};

Details.propTypes = {
    details: PropTypes.any,
}

export const Table = ({ table }) => {
    const rows = table?.map((row, index) => row.split('|').map(cell => cell.trim())).filter(row => row.length > 1);
    return (
        <div className='my-4' style={table ? {} : { display: 'none' }}>
            {table?.length !== 0 && (
                <>
                    <h5>Table</h5>
                    <table border="1" cellPadding="5">
                        <tbody>
                            {rows?.map((row, index) => (
                                <tr key={index}>
                                    {row?.map((cell, i) => (
                                        <td key={i}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

Table.propTypes = {
    table: PropTypes.any,
}

export const KeyPoints = ({ keyPoints }) => {
    return (
        <div className='my-4' style={keyPoints ? {} : { display: 'none' }}>
            {keyPoints?.length !== 0 && (
                <>
                    <h5>Key Points</h5>
                    <ul>
                        {keyPoints?.map((point, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: point }}></li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

KeyPoints.propTypes = {
    keyPoints: PropTypes.any,
}

export const Additional = ({ additional }) => {
    return (
        <div className='my-4' style={additional ? {} : { display: 'none' }}>
            {additional?.length !== 0 && (
                <>
                    <h5>Additional</h5>
                    <ul>
                        {additional?.map((point, index) => (
                            <li key={index} dangerouslySetInnerHTML={{ __html: point }}></li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

Additional.propTypes = {
    additional: PropTypes.any,
}