import PropTypes from "prop-types";
import React, {useState} from "react";

import commonStyles from './../../Issues.module.scss';
import styles from './issuesContainer.module.scss';


import home from "@/assets/survey/oasisHouse1.svg";
import Card, {CardLinkPosition, CardType} from "@/components/Card";
import {Img, Row} from "@/components/Layout";
import {issue_category} from "@/views/PropertyInsights/Issues";

export const IssuesContainer = ({report, displayIssue, handleCard, priceColor}) => {

    const [hover, setHover] = useState({
        id: -1,
        isHovered: false
    });

    return (
        <Row className={`${styles.smallCardsContainer} ${styles.sbcards}`}>
            {report.has_report ?
                displayIssue.filter(issue => !issue.status)
                    .map((issue, index) => (
                        <Card key={index}
                              className={styles.card}
                              type={CardType.smallBox}
                              linkPosition={CardLinkPosition.topRight}
                              action={() => {
                                  handleCard(issue)
                              }}
                              onMouseEnter={() => {
                                  setHover({
                                      id: index,
                                      isHovered: true
                                  })
                              }}
                              onMouseLeave={() => {
                                  setHover({
                                      id: -1,
                                      isHovered: false
                                  })
                              }}>
                            <Card.Header className={styles.card_header}>
                                {issue_category[issue.category]}
                                <p>
                                    {hover.isHovered && issue.issue.length >= 22 && index === hover.id ? issue.issue.slice(0, 19) + '...' : issue.issue}
                                </p>
                            </Card.Header>

                            <Card.Body className={styles.card_body}>
                                <div className={styles.price_container}>
                                    <div className={`${commonStyles.price} ${priceColor}`}>
                                        ${issue.cost_estimate_low}-{issue.cost_estimate_high}
                                    </div>
                                </div>
                                <div className={`${styles.scroll} ${styles.card_body_description}`}>
                                    {issue.context}
                                </div>
                                <div className={`${styles.price_container} ${styles.card_body_image_block}`}>
                                    <div className={commonStyles.price + ' ' + priceColor}>{issue.source}</div>
                                    <Img src={home}/>
                                </div>
                            </Card.Body>
                        </Card>
                    ))
                : <h1>No issues found. Please upload a report</h1>
            }
        </Row>
    )
}

IssuesContainer.propTypes = {
    report: PropTypes.object,
    displayIssue: PropTypes.array,
    handleCard: PropTypes.func,
    priceColor: PropTypes.string,
};