import PropTypes from 'prop-types';
import {useEffect, useState} from "react";

import styles from './Noise.module.scss';
import {Row, Button, Img, Col, Modal} from '../Layout';

import {UserAPI} from '@/api/user';
import roof from '@/assets/survey/roof.svg';

export const Maintenance = ({
                                isOpen, onClose = () => {
    }, onNext = () => {
    }, preference
                            }) => {
    useEffect(() => {
        if (preference[questions.pref]) {
            setQuestions({...questions, response: preference[questions.pref]})
        }
    }, [preference])

    const [questions, setQuestions] = useState({
        title: "Maintenance",
        pref: "Maintenance",
        question: "How much are you willing to tolerate to move into your ideal home?",
        choices: [
            {
                value: '8',
                name: <div>I want it perfect from day one</div>,
            },
            {
                value: '6',
                name: <div>I&apos;m ok with only some minor repairs</div>,
            },
            {
                value: '4',
                name: <div>Replace a new roof or AC system</div>,
            },
            {
                value: '2',
                name: <div>Major room renovations</div>,
            },
        ],
        response: '',
    });

    const handleResponse = (e) => {
        e.preventDefault();
        setQuestions({...questions, response: e.target.value});
    }

    const goNext = () => {
        if (questions.response !== '') {
            const body = {
                "score": parseInt(questions.response),
                "preference": "Maintenance"
            }
            UserAPI.setUserPreferences(body);
            onNext();
        }
    }

    const superscript = <>
        <p style={{display: 'inline', fontWeight: '600', fontSize: '24px', fontFamily: 'Poppins'}}>3</p>
        <p style={{display: 'inline', fontFamily: 'Poppins'}}>/8</p>
    </>

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={questions.title} text={superscript}>
            <Col className={styles.contents_wrapper}>
                <p className={styles.description}>{questions.question}</p>
                    <Row className={styles.main_content}>
                        <Img className={styles.image} src={roof}/>
                        <Col className={styles.questions_wrapper}>
                            {questions.choices.map((choice) => (
                                <Row key={choice.value} className={styles.mcq}>
                                    <Button
                                        className={questions.response === choice.value ? styles.choose : styles.chose}
                                        style={{backgroundColor: '#D1A587'}}
                                        value={choice.value}
                                        onClick={handleResponse}
                                    />
                                    <p>{choice.name}</p>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                <Button className={styles.continueBtn} onClick={goNext}>Save & Continue</Button>
            </Col>
        </Modal>
    )
}

Maintenance.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    preference: PropTypes.any,
}