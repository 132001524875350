import { useState, useEffect } from 'react';

import styles from './index.module.scss';

import { ReportAPI } from '@/api/report';
import { HousesView } from '@/components/HousesView';
import {Col, Contents, Layout, Row} from '@/components/Layout';

export const MyProperties = () => {
    const [reports, setReports] = useState([]);
    useEffect(() => {
        ReportAPI.getReports().then(data => {
            let temp = data.filter(datum => datum.details.mlsid)
            setReports(temp);
        });
    }, []);

    return (
        <Layout className={styles.layout}>
            <Col className={styles.allContents}>
                <Contents>
                    <Row className={styles.title}>Get personalized insights about each property</Row>
                    <Row className={styles.subtitle}>Based on your selected preferences</Row>
                </Contents>
                <HousesView reports={reports} />
            </Col>
        </Layout>
    );
};
