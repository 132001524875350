import { XCircle } from '@phosphor-icons/react';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './index.module.scss';
import { UploadModal } from './UploadModal';

import { ReportAPI } from "@/api/report";
import fillerHouse from '@/assets/fillerHouse.png';
import houseIcon from '@/assets/icons/house.svg';
import plusCircleIcon from '@/assets/icons/inversePlusCircle.svg';
import paperclipWhiteIcon from '@/assets/icons/paperclipWhite.svg';
import {Button, Img, Icon, Col} from '@/components/Layout';
import {formatNumber, getReportAddresses} from '@/utils';

export const HouseCard = ({ report, className = '', hasActions = true, comparing = () => { }, refresh = () => { }, ...props }) => {
    if (!report) return null;

    const [refreshedReport, setRefreshedReport] = useState(report);

    const image = refreshedReport.details.imgSrc;
    const beds = formatNumber(refreshedReport.details.bedrooms);
    const baths = formatNumber(refreshedReport.details.bathrooms);
    const area = formatNumber(refreshedReport.details.livingAreaValue);
    const areaUnit = refreshedReport.details.livingAreaUnits;
    const insightsLink = `/my-properties/insights/${refreshedReport?.id}`;
    const { address1, address2 } = getReportAddresses(refreshedReport);
    const [uploadOpen, setUploadOpen] = useState(false);

    const location = useLocation();
    const isComparing = location.pathname === '/comparison';
    const comparisonButtonStyle = {
        backgroundColor: 'white',
        color: 'black'
    };


    useEffect(() => {
        let interval = refreshedReport.details_status !== 'Done' ? setInterval(() => {
            ReportAPI.getReport(refreshedReport.id).then(data => {
                setRefreshedReport(data);
                data.details_status === 'Done' && clearInterval(interval);
            })
        }, 3000) : null

        return () => {
            interval && clearInterval(interval)
        }
    }, [])

    return (
        <Col className={styles.card + ' ' + className} {...props}>
            <UploadModal style={report?.details.mlsid ? {} : { display: 'none' }} report={refreshedReport} isOpen={uploadOpen} onClose={() => setUploadOpen(false)} />

            <div className={styles.imageContainer}>
                <Img src={image || fillerHouse} preloadSrc={fillerHouse} className={styles.mainImage}
                    style={{ borderRadius: '24px' }} />
                {
                    refreshedReport?.details.mlsid ?
                        <>
                            {refreshedReport?.details_status !== "Extracting details..." && refreshedReport?.details_status !== "" ?
                                <Link className={styles.overlay} to={insightsLink}>
                                    {!refreshedReport.has_report && (
                                        <Button className={`${styles.btn} ${styles.card_btn}`}
                                            onClick={e => (e.preventDefault(), setUploadOpen(true))}>
                                            <Icon src={paperclipWhiteIcon} size={24} />
                                            Upload Documents
                                        </Button>
                                    )}
                                    {isComparing ? (
                                        <Button className={`${styles.btn} ${styles.card_btn}`} style={comparisonButtonStyle}
                                            onClick={e => (e.preventDefault(), comparing())}>
                                            <Icon src={plusCircleIcon} size={24} />
                                            Add to Comparison
                                        </Button>
                                    ) : (
                                        <Button className={`${styles.btn} ${styles.card_btn}`}>
                                            <Icon src={houseIcon} size={24} />
                                            View Report
                                        </Button>
                                    )}
                                </Link> : null}
                        </>
                        :
                        <></>
                }
            </div>

            <div className={styles.info}>
                {
                    refreshedReport?.details_status !== "Extracting details..." & refreshedReport?.details_status !== "" ?
                        <>
                            {refreshedReport?.details.mlsid ?
                                <>
                                    {refreshedReport?.details?.bedrooms !== undefined ?
                                        <Fragment>
                                            <span>
                                                <b>{beds}</b> beds
                                            </span>
                                            <span>
                                                <b>{baths}</b> baths
                                            </span>
                                            <span>
                                                <b>{area}</b> {areaUnit}
                                            </span>
                                        </Fragment>
                                        :
                                        <span>
                                            <b>No report</b>
                                        </span>
                                    }
                                </>
                                :
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <XCircle color='red' size={23} />
                                    <p className={styles.address1 + " ms-1"}>No Longer for Sale</p>
                                </div>}
                        </>
                        :
                        <span>
                            <div>
                                <div className="spinner-border text-dark" style={{ width: '28px', height: '28px' }}
                                    role="status"></div>
                            </div>
                        </span>

                }
            </div>
            <div className={styles.address}>
                {refreshedReport?.details_status !== "Extracting details..." & refreshedReport?.details_status !== "" ?
                    <>
                        <Link className={styles.address2}>
                            {refreshedReport?.name === "Sample Report" ? "Sample Report" : address2}
                        </Link>
                        <Link className={styles.address1}>
                            {refreshedReport?.name === "Sample Report" ? "" : address1}
                        </Link>
                    </>
                    :
                    <>
                        <Link className={styles.address2} to={insightsLink}>
                            {refreshedReport?.name === "Sample Report" ? "Sample Report" : address2}
                        </Link>
                        <Link className={styles.address1} to={insightsLink}>
                            {refreshedReport?.name === "Sample Report" ? "" : address1}
                        </Link>
                    </>
                }
            </div>
        </Col>
    );
};

HouseCard.propTypes = {
    report: PropTypes.object,
    className: PropTypes.string,
    hasActions: PropTypes.bool,
    comparing: PropTypes.func,
    refresh: PropTypes.func,
};
