import { track } from '@vercel/analytics/react';

import { api } from './config';

export const UserAPI = {
    async login(username, password) {
        const response = await api.post('/users/login/', { username, password });
        // const response = await api.post('/auth/login/', { username, password });
        return response.data;
    },
    async register(email, password1) {
        let formdata = new FormData();
        formdata.append('email', email);
        formdata.append('password1', password1);
        formdata.append('X-CSRFToken', api.defaults.headers.post['X-CSRFToken']);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const response = await api.post('/accounts/signup/', formdata, config);
        return response.data;
    },
    async logout() {
        let response;
        try {
            response = await api.get('/users/logout/');
            // response = await api.get('/auth/logout/');
        } catch (e) {
            throw e;
        } finally {
            document.cookie.split(';').forEach(function (c) {
                document.cookie = c
                    .replace(/^ +/, '')
                    .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
            });
        }
        return response.data;
    },
    async me() {
        const response = await api.get('/users/whoami/');
        //  const response = await api.get('/auth/user/');
        return response.data;
    },
    async setProfile(email, first_name, last_name) {
        let formdata = new FormData();
        formdata.append('email', email);
        formdata.append('first_name', first_name);
        formdata.append('last_name', last_name);
        formdata.append('X-CSRFToken', api.defaults.headers.post['X-CSRFToken']);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const response = await api.post('/users/profile/', formdata, config)
    },
    async session() {
        const response = await api.get('/users/session/');
        return response.data;
    },
    async changePassword(oldpassword, password1, password2) {
        let formdata = new FormData();
        formdata.append('oldpassword', oldpassword);
        formdata.append('password1', password1);
        formdata.append('password2', password2);
        formdata.append('X-CSRFToken', api.defaults.headers.post['X-CSRFToken']);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const response = await api.post('/accounts/password/change/', formdata, config);
        track('Forgot Password')
        return response;
    },
    async emailNewPassword(email) {
        let formdata = new FormData();
        formdata.append('email', email);
        formdata.append('X-CSRFToken', api.defaults.headers.post['X-CSRFToken']);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        const response = await api.post('/accounts/password/reset/', formdata, config);
        track('Reached New Password Link')
        return response.data;
    },
    async setCSRF() {
        function getCSRFTokenFromCookie() {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith('csrftoken=')) {
                    return cookie.substring('csrftoken='.length, cookie.length);
                }
            }
            return null;
        }
        let csrfToken = await getCSRFTokenFromCookie();
        if (!csrfToken) {
            const response = await api.get('/users/csrf/');
            csrfToken = response.data['X-CSRFToken'];
        }
        api.defaults.headers.post['X-CSRFToken'] = csrfToken;
        return csrfToken;
    },
    async getUserPreferences() {
        let response;

        try {
            response = await api.get('/users/user-preferences/');
        } catch (e) {
            console.log(e)
        }

        return response.data;
    },
    async setUserPreferences(body) {
        let response;

        try {
            response = await api.post('/users/user-preferences/', body);
        } catch (e) {
            console.log(e)
        }

        return response;
    },
    async getUser(id) {
        const response = await api.get(`/users/whoami/?id=${id}`);
        //   const response = await api.get(`/auth/user/?id=${id}`);
        return response.data;
    },
    async getBaseQuiz() {
        const response = await api.get('https://staging-api.homescore.co/users/getSections/');
        return response.data;
    },
    async getSectionResponse() {
        const response = await api.get('https://staging-api.homescore.co/users/section_response/');
        return response.data;
    },
    async getAllQuestions() {
        const response = await api.get('https://staging-api.homescore.co/users/question_response/');
        return response.data;
    },
    async questionResponse(body) {
        const response = await api.post('https://staging-api.homescore.co/users/question_response/', body);
        return response.data;
    },
    async processSection(body) {
        const response = await api.post('https://staging-api.homescore.co/users/quiz/', body)
        return response.data;
    },
    async summary(body) {
        const response = await api.post('https://staging-api.homescore.co/users/summary/', body)
        return response.data;
    }
};
