import { track } from '@vercel/analytics/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Amenities } from './Amenities';
import { Complete } from './Complete';
import { CurbAppeal } from './CurbAppeal';
import { Energy } from './Energy';
import { HomeSize } from './HomeSize';
import { Maintenance } from './Maitenance';
import { NaturalLight } from './NaturalLight';
import { Noise } from './Noise';
import { School } from './School';

import { UserAPI } from '@/api/user';
import { Button } from '@/components/Layout'
import { ColorAccent } from '@/constants';

export const Survey = ({ title, className = "", style = {} }) => {
    const [survey, setSurvey] = useState({
        noise: false,
        energy: false,
        maitenance: false,
        amenities: false,
        home_size: false,
        natural_light: false,
        schools: false,
        curb_appeal: false,
        complete: false,
    });

    const navigate = useNavigate();

    const [prefs, setPrefs] = useState({
        "Noise Level": '',
        "Energy Efficiency": '',
        "Maintenance": '',
        "Amenities": '',
        "Size and Layout": '',
        "Natural Lighting": '',
        "Good Schools": '',
        "Curb Appeal": ''
    });

    useEffect(() => {
        UserAPI.getUserPreferences().then(data => {
            for (let i = 0; i < data.length; i++) {
                if (prefs.hasOwnProperty(data[i].preference)) {
                    setPrefs((curr) => ({
                        ...curr,
                        [data[i].preference]: data[i].score.toString()
                    }));
                }
            }
        })
    }, [])

    return (
        <div>
            <Noise isOpen={survey.noise} onClose={() => (setSurvey({ ...survey, noise: false }))} onNext={() => (setSurvey({ ...survey, noise: false, energy: true }))} preference={prefs} />
            <Energy isOpen={survey.energy} onClose={() => (setSurvey({ ...survey, energy: false }))} onNext={() => (setSurvey({ ...survey, energy: false, maitenance: true }))} preference={prefs} />
            <Maintenance isOpen={survey.maitenance} onClose={() => (setSurvey({ ...survey, maitenance: false }))} onNext={() => (setSurvey({ ...survey, maitenance: false, amenities: true }))} preference={prefs} />
            <Amenities isOpen={survey.amenities} onClose={() => (setSurvey({ ...survey, amenities: false }))} onNext={() => (setSurvey({ ...survey, amenities: false, home_size: true }))} preference={prefs} />
            <HomeSize isOpen={survey.home_size} onClose={() => (setSurvey({ ...survey, home_size: false }))} onNext={() => (setSurvey({ ...survey, home_size: false, natural_light: true }))} preference={prefs} />
            <NaturalLight isOpen={survey.natural_light} onClose={() => (setSurvey({ ...survey, natural_light: false }))} onNext={() => (setSurvey({ ...survey, natural_light: false, schools: true }))} preference={prefs} />
            <School isOpen={survey.schools} onClose={() => (setSurvey({ ...survey, schools: false }))} onNext={() => (setSurvey({ ...survey, schools: false, curb_appeal: true }))} preference={prefs} />
            <CurbAppeal isOpen={survey.curb_appeal} onClose={() => (setSurvey({ ...survey, curb_appeal: false }))} onNext={() => (setSurvey({ ...survey, curb_appeal: false, complete: true }))} preference={prefs} />
            <Complete isOpen={survey.complete} onClose={() => (setSurvey({ ...survey, complete: false }))} onNext={() => (navigate('/my-properties'))} />
            <Button colorAccent={ColorAccent.white} onClick={() => (setSurvey({ ...survey, noise: true }), track('Start Intake Quiz'))} className={`${className}`} style={style}>
                {title}
            </Button>
        </div>
    )
}

Survey.propTypes = {
    title: PropTypes.any.isRequired,
    className: PropTypes.string,
    style: PropTypes.any,
}