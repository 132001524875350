import PropTypes from 'prop-types';

import styles from './index.module.scss';

import { ReactComponent as BigLongBRHover } from '@/assets/cards/bigLongBRHover.svg';
import { ReactComponent as BigLongTRHover } from '@/assets/cards/bigLongTRHover.svg';
import { ReactComponent as SmallBoxBLHover } from '@/assets/cards/smallBoxBLHover.svg';
import { ReactComponent as SmallBoxTRHover } from '@/assets/cards/smallBoxTRHover.svg';
import { ReactComponent as ArrowDownRight } from '@/assets/icons/arrowDownRight.svg';
import { ReactComponent as ArrowUpRight } from '@/assets/icons/arrowUpRight.svg';
import { Icon } from '@/components/Layout';
import { ColorAccent } from '@/constants';

export const CardType = {
    autoBox: styles.autoBox,
    smallBox: styles.smallBox,
    bigLong: styles.bigLong,
    bigBox: styles.bigBox,
};

export const CardLinkPosition = {
    topRight: styles.linkTopRight,
    bottomLeft: styles.linkBottomLeft,
    bottomRight: styles.linkBottomRight,
};

const hoverBackgrounds = {
    [CardType.smallBox + CardLinkPosition.bottomLeft]: SmallBoxBLHover,
    [CardType.smallBox + CardLinkPosition.topRight]: SmallBoxTRHover,
    [CardType.bigLong + CardLinkPosition.bottomRight]: BigLongBRHover,
    [CardType.bigLong + CardLinkPosition.topRight]: BigLongTRHover,
};

const linkIcons = {
    [CardLinkPosition.topRight]: ArrowUpRight,
    [CardLinkPosition.bottomRight]: ArrowDownRight,
    [CardLinkPosition.bottomLeft]: ArrowUpRight,
};

const Card = ({ children, className = '', type, linkPosition = null, colorAccent = ColorAccent.white, action = () => { }, bgClass = "", ...props }) => {
    const HoverBackground = hoverBackgrounds[type + linkPosition];
    const LinkIcon = linkIcons[linkPosition];
    const linkClass = linkPosition ? `${linkPosition} ${styles.hasLink}` : '';


    return (
        <div className={`${styles.card} ${type} ${linkClass} accent-${colorAccent} ${className}`} {...props}>
            <div className={styles.background + " " + bgClass}>
                {HoverBackground ? <HoverBackground className={styles.hoverImage} /> : null}
            </div>
            <a className={styles.link} onClick={action}>
                {LinkIcon ? <LinkIcon className={styles.linkIcon} /> : null}
            </a>
            {children}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.values(CardType)),
    linkPosition: PropTypes.oneOf(Object.values(CardLinkPosition)),
    colorAccent: PropTypes.oneOf(Object.values(ColorAccent)),
    action: PropTypes.func,
    bgClass: PropTypes.string
};

const Header = ({ children, icon = null, className = '', ...props }) => {
    return (
        <div className={styles.header + ' ' + className} {...props}>
            {icon ? <Icon src={icon} className={styles.icon + ' icon'} /> : null}
            <div className={styles.headerContents + ' ' + ' contents'}>{children}</div>
        </div>
    );
};
Header.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
};
Card.Header = Header;

const Body = ({ children, className = '', ...props }) => {
    return (
        <div className={styles.contents + ' ' + className} {...props}>
            {children}
        </div>
    );
};
Body.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};
Card.Body = Body;

export default Card;
